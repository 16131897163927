import React, { useState, useEffect } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import '../../assets/css/style.css'
import FooterBlack from "components/Footers/FooterBlack.js";
import SectionNavigation from "../index-sections/SectionNavigation.js";

import loadingImg from '../../assets/img/body/loading.gif'
import axios from "axios";
import Search from "./Search";

function SearchPage() {
    let search = useParams()
    const [datas, setDatas] = useState()
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/search`, {
            search: search.search
        }, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(result => {
            setDatas(result.data)
            setIsLoading(false)
        })
    }, [])

    return (
        <>
            {isLoading === true &&
                <div className='bg-loading'>
                    <img src={loadingImg} className='loading-img' alt='loading' />
                </div>
            }
            {isLoading === false &&
                <div className='bg-blank'>
                    <SectionNavigation />
                    <Search datas={datas} />
                    <FooterBlack />
                </div>
            }
        </>
    );
}

export default SearchPage;
