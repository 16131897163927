import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import '../assets/css/style.css'
import FooterBlack from "components/Footers/FooterBlack.js";
import SectionNavigation from "./index-sections/SectionNavigation.js";
import Header from './header-sections/Header'
import Body from "./index-sections/Body";

import loadingImg from '../assets/img/body/loading.gif'
import axios from "axios";
import { getBrowser } from "getBrowser";

function Index() {
  const [datas, setDatas] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (localStorage.getItem('auth')) {
      axios.post(`${process.env.REACT_APP_API_CONTENT2}/series`, { device: getBrowser() }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`
        },
      })
        .then(result => {
          setDatas(result)
          setIsLoading(false)
        })
    } else {
      axios.post(`${process.env.REACT_APP_API_CONTENT2}/guest-series`, { device: getBrowser() })
        .then(result => {
          setDatas(result)
          setIsLoading(false)
        })
    }
  }, [])

  return (
    <>
      {isLoading === true &&
        <div className='bg-loading'>
          <img src={loadingImg} className='loading-img' alt='loading' />
        </div>
      }
      {isLoading === false &&
        <div className='bg-blank'>
          <SectionNavigation />
          <Switch>
            <Route path='/' exact>
              <Header img={datas} />
              <Body datas={datas}
                setIsLoading={setIsLoading}
              />
            </Route>
            <Route path='/index/:search' exact>
              <Body datas={datas} />
            </Route>
          </Switch>
          <FooterBlack />
        </div>
      }
    </>
  );
}

export default Index;
