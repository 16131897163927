import React, { useEffect, useRef } from 'react';
import shaka from 'shaka-player';

const VideoPlayerWithVASTAds = () => {
  const videoRef = useRef(null);
  const adContainerRef = useRef(null);
  let adsManager = null;

  useEffect(() => {
    // Initialize Shaka Player
    const video = videoRef.current;
    const player = new shaka.Player(video);

    // Error event listener for debugging
    player.addEventListener('error', onError);

    // Configure DRM license servers
    player.configure({
      drm: {
        servers: {
          'com.widevine.alpha': 'https://wv.miratech.io/wvproxy/clicense?contentid=V2H_TVBPMMTLG00247',
          'com.microsoft.playready': 'https://mspr.miratech.io/playready/rightsmanager.asmx',
        },
        advanced: {
          'com.widevine.alpha': {
            'persistentStateRequired': true,
            'videoRobustness': 'SW_SECURE_CRYPTO',
            'audioRobustness': 'SW_SECURE_CRYPTO'
          }
        }
      }
    });

    // Load the DRM-protected video
    player.load('https://vod-mvhub-h.sky-cdn.com/prod_mvhub_v2/_definst_/path1/V2H_TVBPMMTLG00247/smil:V2H_TVBPMMTLG00247_ep1.smil/manifest.mpd').then(() => {
      console.log('Video loaded successfully!');
    }).catch(onError);

    // Initialize Google IMA SDK for ads
    initializeIMA();

    return () => {
      // Clean up the player and ads when the component unmounts
      player.destroy();
      if (adsManager) {
        adsManager.destroy();
      }
    };
  }, []);

  const initializeIMA = () => {
    const adDisplayContainer = new window.google.ima.AdDisplayContainer(adContainerRef.current, videoRef.current);
    adDisplayContainer.initialize();

    const adsLoader = new window.google.ima.AdsLoader(adDisplayContainer);
    adsLoader.addEventListener(window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, onAdsManagerLoaded, false);
    adsLoader.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, onAdError, false);

    // Request VAST/VMAP Ads
    const adsRequest = new window.google.ima.AdsRequest();
    adsRequest.adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpremidpostlongpod&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&cmsid=496&vid=short_onecue&correlator='; // Replace with your VAST/VMAP ad tag URL

    adsRequest.linearAdSlotWidth = 640;
    adsRequest.linearAdSlotHeight = 360;
    adsRequest.nonLinearAdSlotWidth = 640;
    adsRequest.nonLinearAdSlotHeight = 150;

    adsLoader.requestAds(adsRequest);
  };

  const onAdsManagerLoaded = (event) => {
    const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
    adsManager = event.getAdsManager(videoRef.current, adsRenderingSettings);

    // Add event listeners for handling ads
    adsManager.addEventListener(window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, onContentPauseRequested);
    adsManager.addEventListener(window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, onContentResumeRequested);
    adsManager.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, onAdError);

    try {
      adsManager.init(640, 360, window.google.ima.ViewMode.NORMAL);
      adsManager.start();
    } catch (adError) {
      console.error('AdsManager could not be started', adError);
    }
  };

  const onContentPauseRequested = () => {
    videoRef.current.pause();
  };

  const onContentResumeRequested = () => {
    videoRef.current.play();
  };

  const onAdError = (adErrorEvent) => {
    console.error('Ad error:', adErrorEvent.getError());
    if (adsManager) {
      adsManager.destroy();
    }
  };

  const onError = (event) => {
    //console.error('Error code', event.detail.code, 'object', event);
    console.error('error object', event);
  };

  return (
    <div>
      <div ref={adContainerRef} style={{ position: 'absolute',top:'5%', left:'20%', zIndex: 1 }}></div>
      <video ref={videoRef} controls style={{ width: '100%', height: 'auto' }} />
    </div>
  );
};

export default VideoPlayerWithVASTAds;
