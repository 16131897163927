import React, { useEffect, useRef, useState } from 'react'

import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function AutoLogin(props) {

const query = new URLSearchParams(window.location.search);
const AccToken = query.get('AccToken')
const Pid = query.get('Pid')
//console.log(AccToken)
localStorage.setItem('auth', AccToken)
localStorage.setItem('login', 'success')

//console.log(window.localStorage.getItem('auth'))
//console.log(window.localStorage.getItem('login'))

window.location.reload()
window.location.href = '/package/'+Pid
}