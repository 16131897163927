import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getOS } from 'getOs';

export default function AppleLogin() {
    const token = useParams()
    const [msg, setMsg] = useState('Loading...')
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/loginsc`, {
            email: 'apple@email.com',
            social_type: 'apple',
            token: token.token,
            device: getOS()[1]
        }).then(result => {
            if (result.data.res_code == '0000') {
                localStorage.setItem('auth', result.data.res_data)
                localStorage.setItem('login', 'success')
                window.location.href = 'https://mvhubplus.com/login'
            } else {
                console.log(result)
                setMsg('การล็อคอินล้มเหลว')
            }
        }).catch(err => console.error(err))
    })
    return (
        <div className='bg-img'>
            {msg}
        </div>
    )
}
