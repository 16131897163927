/*
If you are using server-side rendering, remember that this component should be loaded on client-side
shaka player needs to be loaded on client-side, loading it on server-side may lead to error or undesired results
*/


/*
importing dependencies and CSS file(s) required for UI customization
*/
import React from 'react';
// import 'shaka-player/dist/controls.css';
import '../../assets/css/controls.css'
import loadingImg from "../../assets/img/body/logomvhubplus.jpg"
// import '../../assets/css/controls.css'

const shaka = require('shaka-player/dist/shaka-player.ui.js');

//Creating class component
class TvPlayer extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			url: this.props.url,
			nextUrl: this.props.nextUrl,
			beforeUrl: this.props.previousUrl,
			lastEp: this.props.lastEp,
			license: this.props.license,
			nowTime: 0
		};
		this.setState = {
			url: this.props.url,
			nextUrl: this.props.nextUrl,
			beforeUrl: this.props.previousUrl,
			lastEp: this.props.lastEp,
			license: this.props.license,
			nowTime: 0
		}

		//Creating reference to store video component on DOM
		this.videoComponent = React.createRef();

		//Creating reference to store video container on DOM
		this.videoContainer = React.createRef();

		//Initializing reference to error handlers
		this.onErrorEvent = this.onErrorEvent.bind(this);
		this.onError = this.onError.bind(this);
	}

	onErrorEvent(event) {
		// Extract the shaka.util.Error object from the event.
		this.onError(event.detail);
	}

	onError(error) {
		// Log the error.
		console.error('Error code', error.code, 'object', error);
	}
	async componentDidMount() {
		if (this.state.url !== undefined && this.state.license !== undefined && this.state.url !== null && this.state.license !== null) {

			//Link to MPEG-DASH video
			var manifestUri = this.state.url;
			const nextUrl = this.state.nextUrl
			let nextIcon

			const beforeUrl = this.state.beforeUrl
			let beforeIcon

			const licenseServer = `https://wv.miratech.io/wvproxy/clicense?contentid=${this.state.license}`;
			//Getting reference to video and video container on DOM
			const video = this.videoComponent.current;
			const videoContainer = this.videoContainer.current;

			//Initialize shaka player
			var player = new shaka.Player(video);


			//ios
			if (this.state.url.split('.').pop() == 'm3u8') {
				const req = await fetch('https://fp.miratech.io/prod_fairplay.cer');
				const cert = await req.arrayBuffer();

				// player.configure(`https://fps.drm.qa.tvb.com/fps/tests/Muxed/encrypted/master.m3u8`,
				// 	new Uint8Array(cert));

				player.configure('drm.initDataTransform', (initData, initDataType) => {
					if (initDataType != 'skd')
						return initData;

					// 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
					const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
					const contentId = this.state.license;
					const cert = player.drmInfo().serverCertificate;
					return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert);
				});
				player.getNetworkingEngine().registerRequestFilter((type, request) => {
					if (type != shaka.net.NetworkingEngine.RequestType.LICENSE) {
						return;
					}

					const originalPayload = new Uint8Array(request.body);
					const base64Payload =
						shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);
					const params = 'spc=' + base64Payload;
					request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
					request.body = shaka.util.StringUtils.toUTF8(encodeURIComponent(params));
				});

				player.getNetworkingEngine().registerResponseFilter((type, response) => {
					if (type != shaka.net.NetworkingEngine.RequestType.LICENSE) {
						return;
					}

					let responseText = shaka.util.StringUtils.fromUTF8(response.data);
					// Trim whitespace.
					responseText = responseText.trim();

					// Look for <ckc> wrapper and remove it.
					if (responseText.substr(0, 5) === '<ckc>' &&
						responseText.substr(-6) === '</ckc>') {
						responseText = responseText.slice(5, -6);
					}

					// Decode the base64-encoded data into the format the browser expects.
					response.data = shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
				});
			}





			//android
			if (this.state.url.split('.').pop() != 'm3u8') {
				player.getNetworkingEngine().registerRequestFilter(function (type, request) {
					if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
						request.headers["Content-Type"] = "application/json";

					}
				});

				player.configure({
					drm: {
						servers: {
							'com.widevine.alpha': licenseServer,
							'com.microsoft.playready': 'https://mspr.miratech.io/playready/rightsmanager.asmx',
						},
						advanced: {
							'com.widevine.alpha': {
								'persistentStateRequired': true,
								'videoRobustness': 'SW_SECURE_CRYPTO',
								'audioRobustness': 'SW_SECURE_CRYPTO'
							}
						}
					}
				});
			}

			// Button skip
			if (this.state.nextUrl != undefined && this.state.nextUrl != null) {
				shaka.ui.SkipButton = class extends shaka.ui.Element {
					constructor(parent, controls) {
						super(parent, controls);

						// The actual button that will be displayed
						this.button_ = document.createElement('button');
						this.button_.classList.add('material-icons-round');
						this.button_.classList.add('skip_next-button');
						this.button_.textContent = nextIcon;
						this.parent.appendChild(this.button_);

						// Listen for clicks on the button to start the next playback
						this.eventManager.listen(this.button_, 'click', () => {
							window.location.href = nextUrl
							// const nextManifest = 'https://vod-mvhub.sky-cdn.com/prod_mvhub_v2/_definst_/path1/V2H_TVBPMMTLG00165/smil:V2H_TVBPMMTLG00165_ep2.smil/manifest.mpd'

							// // shaka.ui.Element gives us access to the player object as member of the class
							// this.player.load(nextManifest);
							// if (this.isLastManifest(nextManifest)) {
							// 	// This is the last video. Change icon and aria label.
							// 	this.button_.icon = 'goToBeginningIcon';
							// 	// Screen readers will read "Go to the first video button"
							// 	this.button_.setAttribute('aria-label', 'Go to the first video');
							// } else if (this.isFirstManifest(nextManifest)) {
							// 	// Change icon and aria label back to the skip state
							// 	this.button_.icon = 'skipIcon';
							// 	this.button_.setAttribute('aria-label', 'Skip');
							// }
						});
					}
				};
				// Factory that will create a button at run time.
				shaka.ui.SkipButton.Factory = class {
					/** @override */
					create(rootElement, controls) {
						return new shaka.ui.SkipButton(rootElement, controls);
					}
				};
				// Register our factory with the controls, so controls can create button instances.
				shaka.ui.Controls.registerElement('skip', new shaka.ui.SkipButton.Factory());
			}

			// Button before
			shaka.ui.BeforeButton = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.classList.add('material-icons-round');
					this.button_.classList.add('skip_skip_previous');
					this.button_.textContent = beforeIcon;
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(this.button_, 'click', () => {
						const nextManifest = /* Your logic to pick the next video to be played */
							window.location.href = beforeUrl

						// shaka.ui.Element gives us access to the player object as member of the class
						if (window.location.href.split('/').pop() == 'EP.1') {
							// This is the last video. Change icon and aria label.
							this.button_.textContent = 'skip_previou';
							// Screen readers will read "Go to the first video button"
							this.button_.setAttribute('aria-label', 'Go to the first video')
						}
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.BeforeButton.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.BeforeButton(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('before', new shaka.ui.BeforeButton.Factory());

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.test = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// this.eventManager.listen(this.video, 'seeking', () => {
					// 	console.log('seeking')
					// });
					this.eventManager.listen(this.controls, 'timeandseekrangeupdated', () => {
						let displayTime = this.controls.getDisplayTime();
						const seekRange = this.player.seekRange();
						if (displayTime >= Math.floor(seekRange.end) && seekRange.end != 0) {
							if (nextUrl.split('/').pop() != 'EP.0')
								window.location.href = nextUrl
						}
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.test.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.test(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('test', new shaka.ui.test.Factory());

			///////////////////////////////////////////////////////////////////////////////

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.play = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.classList.add('material-icons-round');
					this.button_.classList.add('playCenter');
					this.button_.textContent = 'play_circle_outline';
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(this.controls, 'timeandseekrangeupdated', async () => {
						if (video.paused) {
							this.button_.textContent = 'play_circle_outline';
						} else {
							this.button_.textContent = 'pause_circle_outline';
						}
					})

					this.eventManager.listen(this.button_, 'click', () => {
						if (video.paused) {
							video.play()
						} else {
							video.pause()
						}
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.play.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.play(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('play', new shaka.ui.play.Factory());

			///////////////////////////////////////////////////////////////////////////////

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.replay = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.classList.add('material-icons-round');
					this.button_.classList.add('replay_10');
					this.button_.textContent = 'replay_10';
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(this.button_, 'click', () => {
						video.currentTime -= 10
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.replay.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.replay(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('replay', new shaka.ui.replay.Factory());

			///////////////////////////////////////////////////////////////////////////////

			///////////////////////////////////////////////////////////////////////////////////////////////////////////
			shaka.ui.forward = class extends shaka.ui.Element {
				constructor(parent, controls) {
					super(parent, controls);

					// The actual button that will be displayed
					this.button_ = document.createElement('button');
					this.button_.classList.add('material-icons-round');
					this.button_.classList.add('forward_10');
					this.button_.textContent = 'forward_10';
					this.parent.appendChild(this.button_);

					// Listen for clicks on the button to start the next playback
					this.eventManager.listen(this.button_, 'click', () => {
						video.currentTime += 10
					});
				}
			};
			// Factory that will create a button at run time.
			shaka.ui.forward.Factory = class {
				/** @override */
				create(rootElement, controls) {
					return new shaka.ui.forward(rootElement, controls);
				}
			};
			// Register our factory with the controls, so controls can create button instances.
			shaka.ui.Controls.registerElement('forward', new shaka.ui.forward.Factory());

			///////////////////////////////////////////////////////////////////////////////

			//Setting UI configuration JSON object
			const uiConfig = {
				seekBarColors: {
					base: 'rgba(255, 255, 255, 0.3)',
					buffered: 'rgba(255, 255, 255, 0.9)',
					played: 'rgb(255, 242, 0)',
					adBreaks: 'rgb(255, 242, 0)',
				},
				volumeBarColors: {
					base: 'rgba(255, 255, 255, 0.3)',
					level: 'rgb(255, 242, 0)'
				},
				// addBigPlayButton: true,
				addSeekBar: false
			};

			//Configuring elements to be displayed on video player control panel
			uiConfig['controlPanelElements'] = ['play_pause', 'spacer', 'mute', 'volume', 'fullscreen',];
			uiConfig['overflowMenuButtons'] = ['quality', 'language', 'captions', 'playback_rate']
			//Setting up shaka player UI
			const ui = new shaka.ui.Overlay(player, videoContainer, video);

			ui.configure(uiConfig); //configure UI
			ui.getControls();
			// Listen for error events.
			player.addEventListener('error', this.onErrorEvent);

			player.configure({
				streaming: {
					bufferingGoal: 100,
					bufferBehind: 100
				}
			})
			// Try to load a manifest.
			// This is an asynchronous process.
			player.load(manifestUri, 0).then(function () {
				let timerId = null;
				function onStateChange(status) {
					clearTimeout(timerId);

					// Show the button, then quickly hide it again.  The transition will animate hiding it.
					const button = document.querySelector('.status-button');
					button.classList.add(status);
					button.value = 'skip_next'
					timerId = setTimeout(() => button.classList.value = 'status-button', 200);
				}

				const videoOnpress = (event) => {
					if (event.code == 'KeyM') {
						if (video.muted == true) {
							onStateChange('volumeUp')
							video.muted = false
						} else {
							onStateChange('mute')
							video.muted = true
						}
					}
					if (event.code == 'Space') {
						video.paused ? video.play() : video.pause()
					}
					if (event.code == 'ArrowRight') {
						onStateChange('forward')
						video.currentTime += 10
					}
					if (event.code == "ArrowLeft") {
						onStateChange('replay')
						video.currentTime -= 10
					}
					if (event.code == 'ArrowUp') {
						onStateChange('volumeUp')
						video.volume = video.volume >= 0.9 ? 1.0 : parseFloat(video.volume + 0.1).toFixed(1)
					}
					if (event.code == 'ArrowDown') {
						onStateChange('volumeDown')
						video.volume = video.volume <= 0.1 ? 0.0 : parseFloat(video.volume - 0.1).toFixed(1)
					}
					if (event.code == 'KeyF') {
						if (document.fullscreenElement) {
							document.exitFullscreen()
						} else {
							document.getElementById('video-container').requestFullscreen()
						}

					}
				}
				document.addEventListener('keydown', (event) => videoOnpress(event))
				// video.addEventListener('pause', () => onStateChange('pause'));
				// video.addEventListener('play', () => onStateChange('play'));
			}).catch(this.onError);  // onError is executed if the asynchronous load fails.	
		}
	}

	render() {
		/*
		Returning video with a container. Remember, when setting up shaka player with custom UI, you must
		add your video component inside a container
		The container will be used by shaka player to add your customized UI for the player
		*/
		return (
			<div className="video-container" id='video-container' ref={this.videoContainer}>
				<span class="status-button material-icons-round"></span>
				<video
					id='shaka'
					autoPlay
					className="shaka-video"
					ref={this.videoComponent}
					poster={loadingImg}
				/>
			</div>
		);
	}
}

export default TvPlayer;