import React, { useState } from 'react'
import {
    Container,
    Col,
    Row
} from "reactstrap";
import '../../assets/css/login.css'
import axios from 'axios';

export default function ForgetPass() {
    const [loginMsg, setLoginMsg] = useState('')
    const [input, setInput] = useState({ username: '' })

    function inputChange(event) {
        const { name, value } = event.target;
        setInput((prevInput) => {
            return {
                ...prevInput,
                [name]: value
            };
        });
    }

    function forgetpassword(event) {
        event.preventDefault()
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/forgetpassword`, {
            "username": input.username
        }).then(result => {
            if (result.data.res_code == '0000') {
                alert(result.data.res_data)
                window.location.href = '/login'
            } else {
                alert(result.data.res_msg)
            }
        })
    }

    return (
        <div className='bg-img'>
            <br />
            <Container className='pb-5'>
                <Row>
                    <Col className='mt-5 mb-5'>
                        <form onSubmit={forgetpassword}>
                            <div id="loginform">
                                <h2 id="headerTitle" style={{ color: 'white' }}>ลืมรหัสผ่าน</h2>
                                <div class="login-row" style={{ color: 'red' }}>
                                    {loginMsg}
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>บัญชีผู้ใช้</label>
                                    <input id='username' name='username' style={{ color: 'black' }} type='text' placeholder='username' onChange={(event) => inputChange(event)} />
                                </div>
                                <div id="button" className="login-row">
                                    <button type='submit' className='login-btn' >ตกลง</button>
                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
