import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Container, Row } from 'reactstrap'

export default function Fav() {
    const [datas, setDatas] = useState('')


    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/userfav`, 'data', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(result => {
            setDatas(result.data.res_data)
        })
    }, [])

    if (datas != '') {
        const renderCard = datas.map(data => {
            return (
                <Link to={`/vdo/${data.vod_id}`}>
                    <Card style={{ 'background-color': 'rgba(14,14,14,0.8)' }}>
                        <div className='gird-col text-center'>
                            <img src={data.imgY1} alt='' />
                            <div>{data.ch_name}</div>
                        </div>
                    </Card>
                </Link>
            )
        })
        return (
            <div className='bg-img'>
                <br />
                <Container>
                    <Row className='justify-content-center'>
                        <Card className='p-3 m-4' style={{ backgroundColor: 'rgb(14,14,14)' }}>
                            <Col>
                                <Row className='text-center'>
                                    <Col>
                                        <Link className='not-A' to='/login/account'>
                                            <i class="fa fa-address-card fa-2x"></i>
                                            <div>
                                                บัญชี
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link className='not-A' to='/login/fav'>
                                            <i class="fa fa-align-justify fa-2x"
                                                style={{ color: '#fff200' }}
                                            ></i>
                                            <div>
                                                รายการโปรด
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link className='not-A' to='/login/history'>
                                            <i class="fa fa-history fa-2x"></i>
                                            <div>
                                                ประวัติการรับชม
                                            </div>
                                        </Link>
                                    </Col>
                                    {/* <Col>
                                        <Link className='not-A' to='/login/package'>
                                            <i class="fas fa-box-open fa-2x"
                                            ></i>
                                            <div>
                                                แพ็คเกจ
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link className='not-A' to='/login/coupon'>
                                            <i class="fas fa-ticket-alt fa-2x"></i>
                                            <div>
                                                แลกคูปอง
                                            </div>
                                        </Link>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Card>
                        <Container className='pb-5'>
                            <Row>
                                <Col>
                                    <div className='grid-col'>
                                        {renderCard}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Row >
                </Container>
            </div>
        )
    } else {
        return <div className='bg-img'>
            <br />
            <Container>
                <Row className='justify-content-center'>
                    <Card className='p-3 m-4' style={{ backgroundColor: 'rgb(14,14,14)' }}>
                        <Col>
                            <Row className='text-center'>
                                <Col>
                                    <Link className='not-A' to='/login/account'>
                                        <i class="fa fa-address-card fa-2x"></i>
                                        <div>
                                            บัญชี
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/fav'>
                                        <i class="fa fa-align-justify fa-2x"
                                            style={{ color: '#fff200' }}
                                        ></i>
                                        <div>
                                            รายการโปรด
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/history'>
                                        <i class="fa fa-history fa-2x"></i>
                                        <div>
                                            ประวัติการรับชม
                                        </div>
                                    </Link>
                                </Col>
                                {/* <Col>
                                    <Link className='not-A' to='/login/package'>
                                        <i class="fas fa-box-open fa-2x"
                                        ></i>
                                        <div>
                                            แพ็คเกจ
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/coupon'>
                                        <i class="fas fa-ticket-alt fa-2x"
                                        ></i>
                                        <div>
                                            แลกคูปอง
                                        </div>
                                    </Link>
                                </Col> */}
                            </Row>
                        </Col>
                    </Card>
                </Row >
            </Container>
        </div>
    }

}
