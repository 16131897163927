import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

function Header(props) {
    let { img } = props
    const renderBanner = img.data.res_data.banner_web.map(data => {
        return (
            <Link to={`/vdo/${data.vod_id}`}>
                <div>
                    <img src={data.img} alt={data.vod_id}
                    // style={{ maxHeight: '700px' }}
                    />
                </div>
            </Link>
        )
    })
    return (
        <>
            <Carousel
                showStatus={false}
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                transitionTime={1000}
                interval={6000}
            >
                {renderBanner}
            </Carousel>
        </>
    );
}

export default Header;
