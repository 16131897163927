import React, { useEffect, useRef, useState } from 'react'

import { useParams } from 'react-router-dom';
import VideoPlayer from 'components/ShakaPlayer/TvPlayer';
import axios from 'axios';
import { getOS } from 'getOs';
import menu from '../../assets/img/body/svg/reply_white_48dp.svg'
function Tv(props) {
    const { index } = useParams();
    const datasRef = useRef()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (localStorage.getItem('auth')) {
            axios.post(`${process.env.REACT_APP_API_CONTENT2}/live`, 'tv', {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('auth')}`
                },
            }).then(result => {
                if (result) {
                    datasRef.current = result.data.res_data.live[index].link
                    // lastEpRef.current = result.data.res_data.episode.length
                    // licenseRef.current = result.data.res_data.ch_name
                    // setLink(result.data.res_data.episode.filter(data => data.ep_name == ep))
                }
            }).finally(() => {
                if (datasRef.current.split('.').pop() == 'm3u8') {
                    datasRef.current = datasRef.current.replace('prod_mvhub_v2', 'prod_mvhub_v2_1')
                }
                setIsLoading(false)
            })
        } else {
            axios.post(`${process.env.REACT_APP_API_CONTENT2}/guest-live`, {
                device: getOS()[1]
            }).then(result => {
                datasRef.current = result.data.res_data.live[index].link
                // console.log(result.data.res_data.episode.filter(data => data.ep_name == ep))
                // lastEpRef.current = result.data.res_data.episode.length
                // licenseRef.current = result.data.res_data.ch_name
                // datasRef.current = result.data.res_data.episode.filter(data => data.ep_name == ep)
                // setLink(result.data.res_data.episode.filter(data => data.ep_name == ep))
            })
                .finally(() => {
                    if (datasRef.current.split('.').pop() == 'm3u8') {
                        datasRef.current = datasRef.current.replace('prod_mvhub_v2', 'prod_mvhub_v2_1')
                    }
                    setIsLoading(false)
                })
        }

    }, [])
    if (isLoading === true) {
        return (
            <div></div>
        )
    }
    if (isLoading === false) {
        return (
            <div className='bg-blank'>
                <VideoPlayer
                    url={datasRef.current}
                    license='VPH=2000'
                />
                <a className="menu not-A" style={{color:'white'}} href={`/tv`}><img src={menu} alt='menu'/></a>
            </div >
        )
    }
}

export default Tv