import React from 'react'
import '../../assets/css/login.css'
import {
    Container,
    Col,
    Row,
} from "reactstrap";
import { Link } from "react-router-dom";

export default function Body(props) {
    const renderCard = props.datas.data.res_data.vod.map(data => {
        return (
            <>
                <h3 className='ml-3 mb-3' >{data.coming_name}</h3>
                <Row>
                    <Col md='6'>
                        <Link to={`/vdo/${data.vod_id}`}>
                            <img className='pl-3 pr-3 pt-3 pb-3 coming-img' src={data.coming_img} alt={data.coming_name} />
                        </Link>
                    </Col>
                    <Col md='6'>
                        <Col>
                            <h3 className='mb-1'>{data.coming_short_detail}</h3>
                            <p className='coming-text-detail'>แนว: {data.coming_genre}</p>
                            <p className='coming-text-detail'>บทประพันธ์โดย: </p>
                            <p className='coming-text-detail'>กำกับการแสดงโดย: {data.coming_director}</p>
                            <p className='coming-text-detail'>ผลิตโดย: </p>
                            <p className='coming-text-detail'>ช่องออกอากาศ: </p>
                            <p className='coming-text-detail'>นักแสดงนำ: {data.coming_star}</p>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className='coming-text-detail ml-3'>เนื้อเรื่องโดยย่อ</p>
                        <p className='coming-text-detail ml-3'>{data.coming_detail}</p>
                    </Col>
                </Row>
                <hr className='hr' />
            </>
        )
    })
    return (
        <>
            <div className='bg-blank'>
                <Container className='container text-white'>
                    {renderCard}
                </Container>
            </div>
        </>
    )
}
