import React, { useEffect, useState } from 'react'
import {
    Container,
    Col,
    Row
} from "reactstrap";
import '../../assets/css/login.css'
import axios from 'axios';

export default function VerifyPassword() {
    const [loginMsg, setLoginMsg] = useState('')
    const [input, setInput] = useState({ password1: '', password2: '' })

    function inputChange(event) {
        const { name, value } = event.target;
        setInput((prevInput) => {
            return {
                ...prevInput,
                [name]: value
            };
        });
    }

    function submitPassword(event) {
        event.preventDefault()
        const search = window.location.search;
        const params = new URLSearchParams(search);
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/newpassword`, {
            "user_id": params.get('user_id').toString(),
            "password": input.password1.toString()
        }).then(result => {
            console.log(result)
            if (result.data.res_code == '0000') {
                alert('เปลี่ยนรหัสผ่านสำเร็จ')
                window.location.href = '/login'
            } else {
                alert(result.data.res_msg)
            }
        })
    }

    useEffect(() => {
        input.password1 === input.password2 ? setLoginMsg('') : setLoginMsg('รหัสผ่านไม่ตรงกัน')
    }, [input])

    return (
        <div className='bg-img'>
            <br />
            <Container className='pb-5'>
                <Row>
                    <Col className='mt-5'>
                        <form onSubmit={(event) => submitPassword(event)}>
                            <div id="loginform">
                                <h2 id="headerTitle" style={{ color: 'white' }}>กรอกรหัสผ่านใหม่</h2>
                                <div class="login-row" style={{ color: 'red' }}>
                                    {loginMsg}
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>รหัสผ่านใหม่</label>
                                    <input id='password' name='password1' style={{ color: 'black' }} type='password' placeholder='password' onChange={(event) => inputChange(event)} />
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>รหัสผ่านใหม่อีกครั้ง</label>
                                    <input id='password' name='password2' style={{ color: 'black' }} type='password' placeholder='confirst-password' onChange={(event) => inputChange(event)} />
                                </div>
                                {!loginMsg && input.password1 && input.password2 &&
                                    <div id="button" className="login-row">
                                        <button type='submit' className='login-btn' >ตกลง</button>
                                    </div>
                                }
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
