import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Container, Row } from 'reactstrap'
import '../../assets/css/style.css'

export default function Package(props) {

    const renderPackage = props.datas.res_data.package_history.map(data => {
        return (
            <Card style={{ 'background-color': 'rgba(14,14,14,0.8)' }}>
                <div className='gird-col text-center'>
                    <img src={data.img_web} alt='' />
                    <div>{data.buy_date}</div>
                </div>
            </Card>
        )
    })
    return (
        <div className='bg-img'>
            <br />
            <Container className='pb-5'>
                <Row className='justify-content-center'>
                    <Card className='p-3 m-4' style={{ backgroundColor: 'rgb(14,14,14)' }}>
                        <Col>
                            <Row className='text-center'>
                                <Col>
                                    <Link className='not-A' to='/login/account'>
                                        <i class="fa fa-address-card fa-2x"></i>
                                        <div>
                                            บัญชี
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/fav'>
                                        <i class="fa fa-align-justify fa-2x"></i>
                                        <div>
                                            รายการโปรด
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/history'>
                                        <i class="fa fa-history fa-2x"></i>
                                        <div>
                                            ประวัติการรับชม
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/package'>
                                        <i class="fas fa-box-open fa-2x"
                                            style={{ color: '#fff200' }}
                                        ></i>
                                        <div>
                                            แพ็คเกจ
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/coupon'>
                                        <i class="fas fa-ticket-alt fa-2x"></i>
                                        <div>
                                            แลกคูปอง
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Card>
                    <Container>
                        <Row>
                            <Col>
                                <div className='grid-col'>
                                    {renderPackage}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row >
            </Container >
        </div >
    )
}
