import axios from 'axios'
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Container, Row } from 'reactstrap'
import { UserContext } from 'UserContext'
import liff from '@line/liff'
import '../../assets/css/style.css'
export default function Account(props) {
    const { userDataContext, setUserDataContext } = useContext(UserContext)
    const [phoneEdit, setPhoneEdit] = useState(false)
    const [phoneInput, setPhoneInput] = useState()
    const [otpEdit, setOtpEdit] = useState(false)
    const [otpInput, setOtpInput] = useState()
    const [emailInput, setEmailInput] = useState()
    // const [userInfoEdit, setUserInfoEdit] = useState(false)
    const [nameInput, setNameInput] = useState()
    const [nameEdit, setNameEdit] = useState(false)
    const [genderInput, setGenderInput] = useState()
    const [genderEdit, setGenderEdit] = useState(false)
    const [bdayInput, setBdayInput] = useState()
    const [bdayEdit, setBdayEdit] = useState(false)

    function submitPhoneEdit() {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/update/userinfo`, {
            user_phone: phoneInput
        }, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(() => {
            window.location.reload()
        })
    }

    function submitNameEdit() {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/update/userinfo`, {
            name: nameInput
        }, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(() => {
            window.location.reload()
        })
    }

    function submitGenderEdit() {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/update/userinfo`, {
            gender: genderInput
        }, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(() => {
            window.location.reload()
        })
    }

    function submitBdayEdit() {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/update/userinfo`, {
            birthday: bdayInput
        }, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(() => {
            window.location.reload()
        })
    }

    function logout() {
        liff.init({ liffId: '1656757415-pbVOdmWy' }, () => {
            if (liff.isLoggedIn()) {
                liff.logout();
            }
        })
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/logout`, 'data', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        })
            .then(() => {
                localStorage.removeItem('auth')
                localStorage.removeItem('login')
                setUserDataContext('')
            }).finally(() => {
                axios.post(`${process.env.REACT_APP_API_CONTENT2}/logout/session`, 'logout', {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('auth')}`
                    }
                }).then(() => window.location.href = '/logout')
            })
    }

    function submitChangePass() {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/forgetpassword`, {
            "username": props.datas.res_data.username
        }).then(result => {
            if (result.data.res_code == '0000') {
                alert(result.data.res_data)
            } else {
                alert(result.data.res_msg)
            }
        })
    }

    function sendOtp() {
        axios.post(`${process.env.REACT_APP_API_STAGING}/user/delete/check`,  {
            email: emailInput
        }, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(result => {
            if (result.data.res_code == '0000') {
                alert(result.data.res_data)
                setOtpEdit(true)
            } else {
                alert(result.data.res_msg)
            }
        })
    }

    function submitOtpEdit() {
        axios.post(`${process.env.REACT_APP_API_STAGING}/user/delete/submit`, {
            otp: otpInput
        }, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(result => {
            if (result.data.res_code == '0000') {
                alert(result.data.res_data)
                logout()
            } else {
                alert(result.data.res_msg)
                window.location.reload()
            }
        })
    }
    return (
        <div className='bg-img'>
            <br />
            <Container className='pb-5'>
                <Row className='justify-content-center'>
                    <Card className='p-3 m-4' style={{ backgroundColor: 'rgb(14,14,14)' }}>
                        <Col>
                            <Row className='text-center'>
                                <Col>
                                    <Link className='not-A' to='/login/account'>
                                        <i class="fa fa-address-card fa-2x"
                                            style={{ color: '#fff200' }}></i>
                                        <div>
                                            บัญชี
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/fav'>
                                        <i class="fa fa-align-justify fa-2x"></i>
                                        <div>
                                            รายการโปรด
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/history'>
                                        <i class="fa fa-history fa-2x"></i>
                                        <div>
                                            ประวัติการรับชม
                                        </div>
                                    </Link>
                                </Col>
                                {/* <Col>
                                    <Link className='not-A' to='/login/package'>
                                        <i class="fas fa-box-open fa-2x"></i>
                                        <div>
                                            แพ็คเกจ
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/coupon'>
                                        <i class="fas fa-ticket-alt fa-2x"></i>
                                        <div>
                                            แลกคูปอง
                                        </div>
                                    </Link>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <span className='text-yellow'>
                                            ชื่อผู้ใช้:
                                        </span>
                                        <span className='ml-1'>
                                            {props.datas.res_data.username}
                                        </span>
                                    </div>
                                    <div>
                                        <span className='text-yellow'>
                                            รหัสสมาชิก:
                                        </span>
                                        <span className='ml-1'>
                                            {props.datas.res_data.user_id}
                                        </span>
                                    </div>
                                    <div>
                                        <span className='text-yellow'>
                                            อีเมล:
                                        </span>
                                        <span className='ml-1'>
                                            {props.datas.res_data.email}
                                        </span>
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <span>
                                                <span className='text-yellow'>
                                                    เบอร์มือถือ:
                                                </span>
                                                {phoneEdit === false &&
                                                    <span className='ml-1'>
                                                        {props.datas.res_data.user_phone}
                                                    </span>
                                                }
                                                {phoneEdit === true &&
                                                    <input type='tel' style={{ color: 'black' }} defaultValue={phoneInput} onChange={(e) => setPhoneInput(e.target.value)} />
                                                }
                                            </span>
                                            {phoneEdit === false &&
                                                <span className='text-right changepass' onClick={() => setPhoneEdit(true)}>
                                                    เปลี่ยนเบอร์
                                                </span>
                                            }
                                            {phoneEdit === true &&
                                                <span className='text-right changepass' onClick={() => submitPhoneEdit()}>
                                                    ยืนยัน
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-between' style={{ padding: '1rem' }}>
                                            <span className='text-white'>
                                                ข้อมูลส่วนตัว
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <span>
                                                <span className='text-yellow'>
                                                    ชื่อ นามสุกล:
                                                </span>
                                                {nameEdit === false &&
                                                    <span className='ml-1'>
                                                        {props.datas.res_data.name}
                                                    </span>
                                                }
                                                {nameEdit === true &&
                                                    <input type='text' style={{ color: 'black' }} defaultValue={nameInput} onChange={(e) => setNameInput(e.target.value)} />
                                                }
                                            </span>
                                            {nameEdit === false &&
                                                <span className='text-right changepass' onClick={() => setNameEdit(true)}>
                                                    เปลี่ยน
                                                </span>
                                            }
                                            {nameEdit === true &&
                                                <span className='text-right changepass' onClick={() => submitNameEdit()}>
                                                    ยืนยัน
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <span>
                                                <span className='text-yellow'>
                                                    เพศ:
                                                </span>
                                                {genderEdit === false &&
                                                    <span className='ml-1'>
                                                        {props.datas.res_data.gender == 'male' ? 'ชาย' : ''}
                                                        {props.datas.res_data.gender == 'female' ? 'หญิง' : ''}
                                                    </span>
                                                }
                                                {genderEdit === true &&
                                                    <select style={{ color: 'black' }} defaultValue={genderInput} onChange={(e) => setGenderInput(e.target.value)}>
                                                    <option value="">กรุณาเลือก</option>
                                                    <option value="male">ชาย</option>
                                                    <option value="female">หญิง</option>
                                                    </select>
                                                }
                                            </span>
                                            {genderEdit === false &&
                                                <span className='text-right changepass' onClick={() => setGenderEdit(true)}>
                                                    เปลี่ยน
                                                </span>
                                            }
                                            {genderEdit === true &&
                                                <span className='text-right changepass' onClick={() => submitGenderEdit()}>
                                                    ยืนยัน
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <span>
                                                <span className='text-yellow'>
                                                วันเดือนปีเกิด:
                                                </span>
                                                <br></br>
                                                {bdayEdit === false &&
                                                    <span className='ml-1'>
                                                        {props.datas.res_data.birthday}
                                                    </span>
                                                }
                                                {bdayEdit === true &&
                                                    <>
                                                    <input type='text' style={{ color: 'black' }} defaultValue={bdayInput} onChange={(e) => setBdayInput(e.target.value)} />
                                                    <br></br>
                                                    example : 31/12/2024
                                                    </>
                                                }
                                            </span>
                                            {bdayEdit === false &&
                                                <span className='text-right changepass' onClick={() => setBdayEdit(true)}>
                                                    เปลี่ยน
                                                </span>
                                            }
                                            {bdayEdit === true &&
                                                <span className='text-right changepass' onClick={() => submitBdayEdit()}>
                                                    ยืนยัน
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    {/* <div>
                                        <span className='text-yellow'>
                                            วันหมดอายุ:
                                        </span>
                                        <span className='ml-1'>
                                            {props.datas.res_data.expire}
                                        </span>
                                    </div>
                                    <div>
                                        <span className='text-yellow'>
                                            วันคงเหลือสะสม:
                                        </span>
                                        <span className='ml-1'>
                                            {props.datas.res_data.expire_day}
                                        </span>
                                    </div> */}
                                </Col>
                            </Row>
                        </Col>
                    </Card>
                </Row >
                <Row className='d-flex text-center mb-1'>
                    <Col>
                        <Button onClick={() => submitChangePass()}>เปลี่ยนรหัสผ่าน</Button>
                    </Col>
                </Row>
                <Row className='d-flex text-center mt-1 mb-2'>
                    <Col>
                        <Button color='danger' onClick={logout}>ออกจากระบบ</Button>
                    </Col>
                </Row>
                {/* <Row className='d-flex text-center mt-4 mb-2'>
                    <Col>
                        {otpEdit === true &&
                        <span className='text-yellow'>
                        Email: 
                            <input type='text' style={{ color: 'black' }} defaultValue={emailInput} onChange={(e) => setEmailInput(e.target.value)} />
                        </span>
                        }
                        {otpEdit === true &&
                            <Button onClick={() => sendOtp()}>ยืนยันลบบัญชี</Button>
                        }
                        {otpEdit === true &&
                        <span className='text-yellow'>
                        OTP: 
                            <input type='text' style={{ color: 'black' }} defaultValue={otpInput} onChange={(e) => setOtpInput(e.target.value)} />
                        </span>
                        }
                        {otpEdit === true &&
                            <Button onClick={() => submitOtpEdit()}>ยืนยัน OTP</Button>
                        }
                    </Col>
                </Row> */}
            </Container >
        </div >
    )
}
