import axios from 'axios';
import FooterFixed from 'components/Footers/FooterFixed';
import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import '../../assets/css/style.css'

// sections for this page
import SectionNavigation from "../index-sections/SectionNavigation.js";
import Body from "../login-sections/Body";
import Account from './Account';
import AppleLogin from './AppleLogin';
import Coupon from './Coupon';
import Fav from './Fav';
import ForgetPass from './ForgetPass';
import History from './History';
import Package from './Package';
import Register from './Register';
import VerifyPassword from './VerifyPassword';

function Index() {
  const [loginStatus, setLoginStatus] = useState(false)
  const [datas, setDatas] = useState()

  useEffect(() => {
    if (localStorage.getItem('login') == 'success') {
      axios.post(`${process.env.REACT_APP_API_CONTENT2}/userinfo`, 'data', {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('auth')}`
        },
      }).then(result => {
        setDatas(result.data)
        setLoginStatus(true)
      })
    }
  }, [])

  return (
    <>
      <Switch>
        <div className="bg-full">
          <SectionNavigation />
          {loginStatus === false &&
            <>
              <Route path={'/login/verify-password'} component={() => {
                return (
                  <>
                    <VerifyPassword data={datas} />
                    <FooterFixed />
                  </>
                )
              }} />
              <Route path='/login' exact component={() =>
                <>
                  <Body />
                  <FooterFixed />
                </>
              } />
              <Route path='/login/apple/token/:token' exact component={() => <AppleLogin />} />
              <Route path='/login/register' exact component={() =>
                <>
                  <Register />
                  <FooterFixed />
                </>
              } />
              <Route path='/login/forgetpass' exact component={() =>
                <>
                  <ForgetPass datas={datas} />
                  <FooterFixed />
                </>
              } />
            </>
          }
          {loginStatus === true &&
            <>
              <Route path={'/login/verify-password'} component={() => {
                return (
                  <>
                    <VerifyPassword data={datas} />
                    <FooterFixed />
                  </>
                )
              }} />
              <Route path='/login/account' exact component={() =>
                <>
                  <Account datas={datas} />
                  <FooterFixed />
                </>
              } />
              <Route path='/login/package' exact component={() => {
                return (
                  <>
                    <Package datas={datas} />
                    <FooterFixed />
                  </>
                )
              }} />
              <Route path='/login/fav' exact component={() => <>
                <Fav datas={datas} />
                <FooterFixed />
              </>
              } />
              <Route path='/login/history' exact component={() => <>
                <History datas={datas} />
                <FooterFixed />
              </>
              } />
              <Route path='/login/coupon' exact component={() =>
                <>
                  <Coupon datas={datas} />
                  <FooterFixed />
                </>
              } />
              <Route path='/login' exact component={() =>
                <>
                  <Account datas={datas} />
                  <FooterFixed />
                </>
              } />
              <Redirect to='/login/account' />
            </>
          }
          {/* <FooterBlack /> */}
        </div>
      </Switch>
    </>
  );
}

export default Index;
