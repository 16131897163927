import React from 'react'

function NextBtn(props) {
    const { className, onClick } = props
    return (
        <div className={className} onClick={onClick}>
            <i class="fa fa-chevron-right" />
        </div>
    )
}

function PrevBtn(props) {
    const { className, onClick } = props
    return (
        <div className={className} onClick={onClick}>
            <i class="fa fa-chevron-left" />
        </div>
    )
}

export { NextBtn, PrevBtn }
