import React from 'react'
import {
  Container,
  Card
} from "reactstrap";

export default function TermTH() {
  return (
    <>
      <Container className='pt-5'>
        <Card className='warning bg-img'>
        <h1>ข้อตกลงการใช้งาน</h1>

<br></br>1. ประกาศสำคัญ
<br></br>(1) กรุณาอ่านข้อกำหนดในการใช้งานฉบับนี้ (“ข้อกำหนดในการใช้งาน”) โดยละเอียด เนื่องจากข้อกำหนดในการใช้งานฉบับนี้จะใช้บังคับกับการใช้บริการของ MVHub Plus ของท่าน ซึ่งสามารถเข้าถึงได้ผ่านทางเว็บไซต์ MVHub Plus ที่ www.mvhubplus.com และแอปพลิเคชัน MVHub Plus (เรียกรวมกันว่า “เว็บไซต์”) การที่ท่านใช้และ/หรือเข้าถึงเว็บไซต์ แสดงว่าท่านตกลงที่จะผูกพันตนตามข้อกำหนดการใช้งานฉบับนี้ (ตามที่อาจมีการปรับปรุงเป็นครั้งคราวไป)
<br></br>(2) เราเป็นผู้ดำเนินงานเว็บไซต์ แต่เราไม่มีอำนาจควบคุมด้านการเรียบเรียงแก้ไขคอนเทนต์บางส่วนบนเว็บไซต์ และในบางกรณี บุคคลภายนอกอื่นๆ จะเป็นผู้จัดหาคอนเทนต์ที่แสดงบนเว็บไซต์ หรือเป็น ผู้ดำเนินงานเว็บไซต์เป็นบางส่วนหรือเว็บไซต์ทางอินเทอร์เน็ตอื่นๆ ที่ท่านอาจเข้าชมหรือเข้าถึงผ่านทางเว็บไซต์ (“ผู้ให้บริการที่เป็นบุคคลภายนอก”)
<br></br>(3) เราจะปรับปรุงข้อกำหนดในการใช้งานฉบับนี้เป็นครั้งคราว ท่านมีความรับผิดชอบในการตรวจสอบดูการปรับปรุงข้อกำหนดในการใช้งานฉบับนี้เมื่อข้อกำหนดในการใช้งานมีผลบังคับใช้หลังจากที่มีการโพสต์ ลงบนเว็บไซต์ ทั้งนี้ การที่ท่านยังคงใช้งานเว็บไซต์ต่อไปหลังจากที่มีการเปลี่ยนแปลงหรือแก้ไขเพิ่มเติมข้อกำหนดและเงื่อนไขดังกล่าว ไม่ว่าท่านจะได้ทบทวนการเปลี่ยนแปลงหรือแก้ไขเพิ่มเติมดังกล่าวหรือไม่ก็ตาม จะถือว่าเป็นการที่ท่านยอมรับที่จะผูกพันตนตามการเปลี่ยนแปลงหรือแก้ไขเพิ่มเติมข้อกำหนดและเงื่อนไขดังกล่าว เว้นแต่เมื่อกฎหมายที่ใช้บังคับกำหนดเป็นอย่างอื่น
<br></br><br></br>2. การลงทะเบียนและการทดลองใช้บริการแบบไม่คิดค่าบริการ
<br></br>(1) บริการของ MVHub Plus
<br></br>(i) นอกเหนือจากสิ่งอื่นๆ แล้ว การที่ท่านจะสามารถเข้าถึงบริการของ MVHub Plus ผ่านทางอุปกรณ์ต่างๆ จะขึ้นอยู่กับระบบปฏิบัติการและ/หรือเบราเซอร์ที่ติดตั้งอยู่บนอุปกรณ์ของท่าน
<br></br>(ii) เราให้บริการของ MVHub Plus บางส่วนแก่ผู้ใช้งานโดยไม่มีค่าบริการ (“บริการของ MVHub Plus แบบไม่คิดค่าบริการ”) และให้บริการคอนเทนต์ ฟีเจอร์ และ/หรือบริการอื่นๆ บางส่วนแก่ผู้ใช้งานที่สมัครรับหนึ่งในบริการแบบมีค่าบริการของเรา อันได้แก่ MVHub Plus Premium หลังจากที่ได้มีการชำระค่าธรรมเนียมสมาชิกที่เกี่ยวข้องแล้ว MVHub Plus Premium จะเรียกรวมกันว่า “บริการของ MVHub Plus แบบคิดค่าบริการ” และจะเรียกแยกกันว่า “บริการของ MVHub Plus แบบคิดค่าบริการ” หากต้องการรายละเอียดเพิ่มเติมเกี่ยวกับบริการของ MVHub Plus แบบไม่คิดค่าบริการและบริการของ MVHub Plus แบบคิดค่าบริการ กรุณาไปที่ https://www.mvhubplus.com
<br></br>(iii) บริการของ MVHub Plus แบบไม่คิดค่าบริการจะสามารถเข้าถึงได้ในเขตพื้นที่ใดก็ตามที่เราเสนอให้บริการแบบไม่คิดค่าบริการดังกล่าว บริการของ MVHub Plus แบบคิดค่าบริการแต่ละแบบจะจำกัดการใช้งานและการเข้าถึงเฉพาะในเขตพื้นที่ที่ท่านได้สมัครรับบริการของ MVHub Plus แบบคิดค่าบริการดังกล่าว
<br></br>(2) การใช้บริการของ MVHub Plus ผ่านทางเครือข่ายอุปกรณ์มือถืออาจมีการคิดค่าบริการการใช้ข้อมูลบนอุปกรณ์มือถือ กรุณาติดต่อผู้ให้บริการข้อมูลบนอุปกรณ์มือถือของท่านเพื่อสอบถามข้อมูลเพิ่มเติม
<br></br>(3) ท่านอาจจำเป็นต้องลงทะเบียนกับเราเพื่อที่จะเข้าถึงบางส่วนของเว็บไซต์ (“ฟีเจอร์ที่สามารถเข้าถึงได้หลังจากลงทะเบียน”) ในกรณีที่มีการกำหนดให้ท่านลงทะเบียน
<br></br>(i) ท่านจะต้องให้ข้อมูลการลงทะเบียนที่ถูกต้อง ครบถ้วน และปรับปรุงล่าสุด
<br></br>(ii) ท่านจะต้องรักษาชื่อผู้ใช้และรหัสผ่านใดๆ ที่เราจัดหาให้แก่ท่านให้ปลอดภัย และ
<br></br>(iii) ท่านอนุญาตให้เราถือเอาได้ว่า บุคคลใดๆ ที่ใช้เว็บไซต์โดยใช้ชื่อผู้ใช้และรหัสผ่านของท่าน จะเป็นตัวท่านเองหรือบุคคลที่ท่านอนุญาตให้ใช้ชื่อผู้ใช้และรหัสผ่านดังกล่าวในการเข้าถึงเว็บไซต์
<br></br>(4) ท่านยอมรับว่าการที่ท่านใช้ฟีเจอร์ที่สามารถเข้าถึงได้หลังจากลงทะเบียนบางส่วนอาจต้องบังคับตามข้อกำหนดและเงื่อนไขเพิ่มเติม ในกรณีดังกล่าว ท่านจะได้รับการบอกกล่าวให้ทราบ และข้อกำหนดและเงื่อนไขเพิ่มเติมดังกล่าว
<br></br>(i) จะใช้บังคับกับการที่ท่านใช้ฟีเจอร์ที่สามารถเข้าถึงได้หลังจากลงทะเบียนดังกล่าว เพิ่มเติมจากจากข้อกำหนดในการใช้งานฉบับนี้ และ
<br></br>(ii) จะใช้บังคับแทนที่ข้อกำหนดในการใช้งานฉบับนี้ ในขอบเขตที่มีความไม่สอดคล้องกันเกิดขึ้น
<br></br><br></br>3. บริการ
<br></br>(1) ท่านยอมรับว่า ในเวลาใดก็ตาม เราอาจใช้ดุลยพินิจของเราแต่เพียงผู้เดียวในการดำเนินการต่อไปนี้ ไม่ว่าจะมีการบอกกล่าวหรือไม่ก็ตาม
<br></br>(i) ปิดและ/หรือระงับการให้บริการของ MVHub Plus ในเวลาใดก็ตาม เพื่อทำการบำรุงรักษา อัปเกรด ทดสอบ และ/หรือซ่อมแซมระบบ
<br></br>(ii) เปลี่ยนแปลงเว็บไซต์ (หรือส่วนหนึ่งส่วนใดของเว็บไซต์) และ
<br></br>(iii) หยุดการให้บริการเว็บไซต์ หยุดการให้บริการของ MVHub Plus (หรือส่วนหนึ่งส่วนใดของบริการของ MVHub Plus) และ/หรือคอนเทนต์ใดๆ แก่ท่าน
<br></br><br></br>4. ค่าบริการ
<br></br>(1) ในขอบเขตที่กฎหมายที่ใช้บังคับอนุญาต เราขอสงวนสิทธิที่จะดำเนินการต่อไปนี้ไม่ว่าในเวลาใดก็ตาม
<br></br>(i) เรียกเก็บค่าบริการสำหรับการที่ท่านใช้บริการ MVHub Plus แบบไม่คิดค่าบริการ และ
<br></br>(ii) เปลี่ยนแปลงหรือแก้ไขเพิ่มเติมข้อกำหนดและเงื่อนไขซึ่งครอบคลุมค่าบริการดังกล่าว สำหรับการเข้าถึงหรือใช้เว็บไซต์ทั้งหมดหรือเป็นบางส่วน
<br></br><br></br>5. คอนเทนต์
<br></br>(1) ท่านจะต้องมีอายุตั้งแต่ 18 ปีขึ้นไปจึงจะสามารถเข้าถึงหรือใช้บริการของ MVHub Plus และ/หรือคอนเทนต์ได้
<br></br>(2) ท่านจะได้รับอนุญาตให้ใช้บริการของ MVHub Plus และรับชมคอนเทนต์ในลักษณะที่เป็นการใช้งานส่วนตัวของท่านเองที่ไม่ใช่การใช้ในทางการค้าเท่านั้น โดยจะต้องเป็นไปตามข้อกำหนดในการใช้งานฉบับนี้
<br></br>(3) ท่านตกลงว่าบริการของ MVHub Plus และคอนเทนต์จะมีไว้สำหรับใช้ท่านรับชมและเข้าถึงเป็นการส่วนตัวเท่านั้น และท่านจะต้องไม่แสดง เล่น ใช้ เข้าถึง หรือรับชมบริการของ MVHub Plus และ/หรือคอนเทนต์ หรืออนุญาตให้บุคคลอื่นใดแสดง เล่น ใช้ เข้าถึง หรือรับชมบริการของ MVHub Plus และ/หรือคอนเทนต์เพื่อวัตถุประสงค์ในทางสาธารณะหรือในทางธุรกิจ เว้นแต่เมื่อได้รับความยินยอมเป็นลายลักษณ์อักษรจากเราก่อน
<br></br>(4) ในการที่ท่านใช้และ/หรือเข้าถึงบริการของ MVHub Plus ท่านจะต้องปฏิบัติตามกฎหมายที่ใช้บังคับทั้งปวง รวมทั้งกฎในการดำเนินการและ/หรือการใช้งานใด ๆ ตามที่จะมีการแก้ไขเพิ่มเติมเป็นครั้งคราว
<br></br>(5) โดยไม่เป็นการจำกัดขอบเขตของข้อ 5(2) และ 5(3) ข้างต้น ท่านจะต้องไม่เรียกเก็บค่าบริการสำหรับการใช้หรือการเข้าถึงบริการของ MVHub Plus และ/หรือคอนเทนต์ใด ๆ หรือสำหรับการเข้าถึงสถานที่ใด ๆ ที่มีการรับชมบริการของ MVHub Plus และ/หรือคอนเทนต์
<br></br>(6) ท่านยอมรับว่าคอนเทนต์บนเว็บไซต์จะได้รับความคุ้มครองลิขสิทธิ์ และเป็นไปได้ที่จะมีสิทธิในทรัพย์สินทางปัญญาอื่น ๆ เว้นแต่เมื่อกฎหมายอนุญาตหรือเมื่อได้รับอนุญาตจากเราอย่างชัดแจ้ง ท่านจะต้องไม่กระทำการ หรืออนุญาตให้บุคคลอื่นใดกระทำการต่าง ๆ ดังต่อไปนี้
<br></br>(i) ขาย ผลิตซ้ำ กระจาย ดัดแปลง แสดง เผยแพร่ แสดงต่อสาธารณะ จัดทำงานสร้างสรรค์ต่อเนื่องโดยอ้างอิงจาก เผยแพร่ซ้ำ หรือใช้คอนเทนต์ใด ๆ ในลักษณะใด ๆ ก็ตามเพื่อวัตถุประสงค์ในทางสาธารณะหรือในทางธุรกิจโดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากเราก่อน
<br></br>(ii) ใช้คอนเทนต์ใด ๆ บนเว็บไซต์อื่นใดหรือในระบบเครือข่ายคอมพิวเตอร์เพื่อวัตถุประสงค์ใด ๆ ก็ตาม
<br></br>(iii) ทำวิศวกรรมย้อนกลับต่อคอนเทนต์ใด ๆ ซึ่งมีซอฟต์แวร์ที่สามารถดาวน์โหลดได้เป็นส่วนประกอบ และ/หรือ
<br></br>(iv) ละเมิดสิทธิในทรัพย์สินทางปัญญาของบุคคลหรือหน่วยงานใดในลักษณะอื่น ๆ ในการใช้และ/หรือเข้าถึงเว็บไซต์หรือคอนเทนต์ใด ๆ
<br></br>(7) สิ่งใดก็ตามที่ท่านทำบนเว็บไซต์หรือโดยเกี่ยวข้องกับเว็บไซต์ จะไม่ถือว่าเป็นการโอนสิทธิในทรัพย์สินทางปัญญาให้แก่ท่าน หรืออนุญาตให้ท่านใช้สิทธิในทรัพย์สินทางปัญญาใด ๆ เว้นแต่เมื่อมีการระบุไว้อย่างชัดแจ้ง
<br></br>(8) ท่านยอมรับว่า
<br></br>(i) เราสามารถเปลี่ยนแปลง เปลี่ยนทดแทน หรือถอดคอนเทนต์ ช่อง และรายการใด ๆ ออก (ไม่ว่าจะมีการโฆษณาหรือไม่ก็ตาม) จากบริการของ MVHub Plus รวมถึงการเปลี่ยนแปลงหรือลดจำนวนชั่วโมงในการออกอากาศของช่องหรือรายการใด ๆ ในบริการของ MVHub Plus และ
<br></br>(ii) ขยาย ลด ถอดออก และ/หรือดัดแปลงส่วนใดของบริการของ MVHub Plus และ/หรือคอนเทนต์ใด ๆ ถอดออก ปิด และ/หรือระงับการส่งผ่านหรือการสตรีมบริการของ MVHub Plus และ/หรือคอนเทนต์ ซึ่งเราพิจารณาตามความคิดเห็นของเราแต่เพียงผู้เดียวแล้วว่าเป็นการละเมิดกฎหมายที่ใช้บังคับหรือไม่สามารถยอมรับได้ในลักษณะอื่น
<br></br><br></br>6. การใช้งานโดยท่าน
<br></br>(1) ท่านจะต้องไม่
<br></br>(i) แทรกแซงหรือรบกวนเว็บไซต์ บริการของ MVHub Plus เซิร์ฟเวอร์ เครือข่าย ซอฟต์แวร์ ฮาร์ดแวร์ หรืออุปกรณ์ที่เชื่อมต่อกับหรือผ่านทางเว็บไซต์และ/หรือบริการของ MVHub Plus
<br></br>(ii) ละเมิดกฎหมายที่ใช้บังคับใด ๆ ในการใช้เว็บไซต์และ/หรือบริการของ MVHub Plus
<br></br>(iii) เก็บรวบรวมหรือจัดเก็บข้อมูลส่วนบุคคลเกี่ยวกับผู้ใช้รายอื่น ๆ ของเว็บไซต์และ/หรือบริการของ MVHub Plus
<br></br>(iv) ยับยั้งหรือขัดขวางบุคคลอื่นใดไม่ให้ใช้เว็บไซต์และ/หรือบริการของ MVHub Plus รวมถึงแต่ไม่จำกัดเพียงด้วยการ “แฮ็ก” หรือทำการเปลี่ยนแปลงส่วนใดของเว็บไซต์และ/หรือบริการของ MVHub Plus
<br></br>(v) เข้าถึงหรือใช้เว็บไซต์ บริการของ MVHub Plus คอนเทนต์ หรือซอฟต์แวร์เพื่อวัตถุประสงค์ที่ไม่ชอบด้วยกฎหมายใด ๆ หรือวัตถุประสงค์ใด ๆ ที่ไม่ได้รับอนุญาตอย่างชัดแจ้งตามข้อกำหนดในการใช้งานฉบับนี้
<br></br>(vi) ดัดแปลง ปรับเปลี่ยน อนุญาตให้ใช้สิทธิช่วง ผลิตซ้ำ กระจาย ทำสำเนา แสวงหาประโยชน์ แปล ขาย ทำวิศวกรรมย้อนกลับ แปลกลับ หรือแยกส่วนส่วนใด ๆ ของเว็บไซต์ บริการของ MVHub Plus ซอฟต์แวร์ และ/หรือคอนเทนต์
<br></br>(vii) นำประกาศเกี่ยวกับลิขสิทธิ์ เครื่องหมายการค้า หรือสิทธิความเป็นเจ้าของอื่น ๆ ซึ่งอยู่บนเว็บไซต์ บริการของ MVHub Plus และ/หรือคอนเทนต์ออก
<br></br>(viii) ทำสำเนาและ/หรือผลิตซ้ำส่วนใดของเว็บไซต์ บริการของ MVHub Plus และ/หรือคอนเทนต์โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจากเราก่อน
<br></br>(ix) ใช้โรบอต สไปเดอร์ แอปพลิเคชันสำหรับการค้นหาหรือรวบรวมข้อมูลจากเว็บไซต์ หรืออุปกรณ์หรือกระบวนการที่ทำงานด้วยมือหรือด้วยระบบอัตโนมัติอื่น ๆ ในการรวบรวม จัดดัชนี “ทำเหมืองข้อมูล” หรือผลิตซ้ำ รบกวน หรือใช้วิธีการหลบหลีกในลักษณะใดก็ตาม ต่อโครงสร้างการนำทางหรือการแสดงผลเว็บไซต์ บริการของ MVHub Plus และ/หรือคอนเทนต์
<br></br>(x) แฮ็ก บุกรุก หรือพยายามแฮ็กหรือบุกรุก ไม่ว่าจะในลักษณะใดก็ตาม ต่อเว็บไซต์ บริการของ MVHub Plus ซอฟต์แวร์ และ/หรือพื้นที่จัดเก็บข้อมูลใด ๆ บนเซิร์ฟเวอร์ของเราหรือของบุคคลภายนอกใดๆ
<br></br>(xi) ดาวน์โหลด ทำสำเนา จัดเก็บ ตัด กระจาย แบ่งปัน หรือเปลี่ยนเส้นทางคอนเทนต์ใด ๆ จากเว็บไซต์และ/หรือบริการของ MVHub Plus ไม่ว่าในลักษณะใดหรือผ่านทางสื่อใด ๆ ก็ตาม
<br></br>(xii) รวบรวมซอฟต์แวร์หรือสิ่งอื่น ๆ ที่มีไวรัส โค้ดที่เป็นอันตราย เวิร์ม โปรแกรมไทม์บอมบ์ โทรจันฮอร์ส
<br></br><br></br>
7. ลิงก์และการโฆษณา
<br></br>(1) เราไม่มีอำนาจควบคุมเว็บไซต์ของบุคคลภายนอกใด ๆ ซึ่งมีการเชื่อมต่อกับเว็บไซต์และ/หรือบริการของ MVHub Plus และจะไม่มีหน้าที่รับผิดชอบต่อเนื้อหาหรือความถูกต้องของเว็บเพจที่อยู่นอกเว็บไซต์หรือเว็บไซต์ของบุคคลภายนอกอื่นใดที่มีการเชื่อมต่อกับเว็บไซต์และ/หรือบริการของ MVHub Plus (รวมถึงแต่ไม่จำกัดเพียงเว็บไซต์ที่เชื่อมต่อผ่านทางโฆษณา)
<br></br>(2) ลิงก์บางส่วนซึ่งปรากฏอยู่บนเว็บไซต์และ/หรือบริการของ MVHub Plus จะถูกสร้างขึ้นโดยอัตโนมัติ และอาจมีลักษณะที่ล่วงละเมิดหรือไม่เหมาะสมสำหรับคนบางส่วน การรวมเอาลิงก์ใดๆ ไว้ไม่ได้แสดงนัยว่าเราให้การสนับสนุนเว็บไซต์ที่มีการเชื่อมต่อดังกล่าว และท่านจะต้องใช้ลิงก์ดังกล่าวโดยรับความเสี่ยงด้วยตัวท่านเอง
<br></br>(3) การที่ท่านติดต่อหรือตกลง หรือมีส่วนร่วมในการส่งเสริมการขายของผู้ลงโฆษณาบนเว็บไซต์และ/หรือบริการของ MVHub Plus จะถือว่าเกิดขึ้นระหว่างท่านกับผู้ลงโฆษณาดังกล่าวเท่านั้น เราจะไม่ต้องรับผิดหรือรับผิดชอบต่อการสูญเสียหรือความเสียหายไม่ว่าจะในรูปแบบใดก็ตามซึ่งเกิดขึ้นโดยเป็นผลจากการตกลงดังกล่าว หรือโดยเป็นผลมาจากการแสดงลิงก์และโฆษณาบนเว็บไซต์และ/หรือบริการของ MVHub Plus
<br></br>(4) เราอาจใช้บริษัทโฆษณาที่เป็นบุคคลภายนอก รวมถึงแต่ไม่จำกัดเพียง Google Ad, Oracle Data Cloud, Integral Ad Science, Lotame และ Nielsen ในการติดตามและ/หรือวัดความมีประสิทธิภาพในการโฆษณาและ/หรือยกระดับประสบการณ์ผู้ใช้ของเราบนเว็บไซต์ บริการของ MVHub Plus และเว็บไซต์อื่น ๆ บริษัทเหล่านี้อาจใช้ข้อมูลบางประการ (เช่น รหัสประจำอุปกรณ์ที่ไม่สามารถใช้ระบุตัวตนบุคคลได้ ไอพีแอดเดรส แต่จะไม่รวมชื่อ ที่อยู่ อีเมลแอดเดรส หรือหมายเลขโทรศัพท์ของท่าน) เกี่ยวกับการที่ท่านเข้าเยี่ยมชมเว็บไซต์ บริการของ MVHub Plus และเว็บไซต์อื่น ๆ เพื่อวัดความมีประสิทธิภาพในการโฆษณาและแสดงโฆษณาเกี่ยวกับสินค้าและบริการที่ท่านสนใจ
<br></br>(5) เราเก็บรวบรวมและใช้เนื้อหาและข้อมูลสาธารณะที่ท่านให้แก่เว็บไซต์อื่น ๆ รวมถึงแต่ไม่จำกัดเพียงโปรไฟล์สาธารณะของท่านในบัญชีโซเชียลมีเดียของท่าน (เช่น บัญชีผู้ใช้ Facebook หากท่านเข้าถึงเว็บไซต์และ/หรือบริการของ MVHub Plus ผ่านทางการล็อกอินเข้าบัญชี Facebook ของท่าน) เพื่อที่จะให้บริการของ MVHub Plus และแสดงโฆษณาเกี่ยวกับสินค้าและบริการที่ท่านสนใจ
<br></br><br></br>8. รหัสประจำอุปกรณ์และข้อมูลอื่น ๆ เกี่ยวกับการเข้าชมเว็บไซต์
<br></br>(1) ในระหว่างที่ท่านใช้และ/หรือเข้าถึงเว็บไซต์ เราจะรวบรวมข้อมูลเกี่ยวกับกิจกรรมของอุปกรณ์ซึ่งใช้ในการเข้าชมเว็บไซต์ รวมถึงรหัสประจำอุปกรณ์ “คุกกี้” และพฤติกรรมการเข้าชมเว็บไซต์ การที่ท่านใช้เว็บไซต์ แสดงว่าท่านตกลงให้เราใช้รหัสประจำอุปกรณ์และ/หรือคุกกี้ตามประกาศเกี่ยวกับการเก็บรวบรวมข้อมูลส่วนบุคคล ประกาศเกี่ยวกับความเป็นส่วนตัว และประกาศเกี่ยวกับคุกกี้ของเรา
<br></br>(2) เราใช้บริษัทโฆษณาที่เป็นบุคคลภายนอกในการแสดงโฆษณาบนเว็บไซต์ของเรา บริษัทเหล่านี้อาจใช้ข้อมูลเกี่ยวกับกิจกรรม (ไม่รวม ชื่อ ที่อยู่ อีเมลแอดเดรส หรือหมายเลขโทรศัพท์ของท่าน) ของอุปกรณ์ (รวมถึงรหัสประจำอุปกรณ์และ/หรือคุกกี้) ในเว็บไซต์นี้และเว็บไซต์อื่นๆ เพื่อวัดความมีประสิทธิภาพในการโฆษณาและแสดงโฆษณาที่ท่านอาจสนใจ
<br></br>(3) เราและ/หรือผู้ประมวลผลข้อมูลของเราอาจโอนรหัสประจำอุปกรณ์และ/หรือคุกกี้ไปยังผู้ให้บริการที่เป็นบุคคลภายนอกที่เราว่าจ้างซึ่งอยู่นอกประเทศไทย ท่านได้ตกลงให้มีการโอนรหัสประจำอุปกรณ์และ/หรือคุกกี้ไปยังผู้ให้บริการที่เป็นบุคคลภายนอกดังกล่าวแล้ว เราจะดูแลให้แน่ใจว่าผู้ให้บริการที่เป็นบุคคลภายนอกดังกล่าวมีการบังคับใช้มาตรการและนโยบายการคุ้มครองข้อมูลที่มีความเข้มงวดไม่น้อยไปกว่ามาตรการและนโยบายของเรา
<br></br><br></br>9.การใช้เว็บไซต์และบริการ MVHub Plus โดยที่ท่านเป็นผู้รับความเสี่ยงเอง
<br></br>(1) ท่านใช้เว็บไซต์และบริการของ MVHub Plus โดยที่ท่านเป็นผู้รับความเสี่ยงเอง ท่านจะต้องประเมินและรับความเสี่ยงทั้งหมดที่เกี่ยวข้องกับการใช้คอนเทนต์ใดๆ รวมถึงการอาศัยอ้างอิงจากความถูกต้อง ความครบถ้วน หรือความมีประโยชน์ของคอนเทนต์ใดๆ ข้อมูลทั้งปวงที่แสดงบนหรือผ่านทางเว็บไซต์และบริการของ MVHub Plus มีไว้เพื่อวัตถุประสงค์ในการให้ข้อมูลเท่านั้น ท่านควรจะขอรับคำแนะนำที่เป็นอิสระสำหรับตัวท่านเองเกี่ยวกับคอนเทนต์ใดๆ
<br></br>(2) เราใช้ความพยายามในการจัดทำเว็บไซต์ที่สะดวกสบายและใช้งานได้จริง แต่เราไม่รับประกันว่าคอนเทนต์จะปราศจากความผิดพลาด หรือเว็บไซต์หรือเซิร์ฟเวอร์ที่ใช้ในการทำงานของเว็บไซต์จะปราศจากไวรัสหรือส่วนประกอบที่เป็นอันตรายอื่นๆ
<br></br>(3) หากการที่ท่านใช้เว็บไซต์และ/หรือบริการของ MVHub Plus ส่งผลให้เกิดความจำเป็นต้องใช้การบริการหรือการเปลี่ยนทดแทนทรัพย์สิน วัสดุ อุปกรณ์ หรือข้อมูล เราจะไม่รับผิดชอบต่อค่าใช้จ่ายต่างๆ ดังกล่าว
<br></br>(4) โดยไม่เป็นการจำกัดขอบเขตของข้อกำหนดข้างต้นนี้ ท่านตกลงว่าเว็บไซต์และบริการของ MVHub Plus จะจัดหาให้ “ตามสภาพจริง” และโดยไม่มีการให้การรับประกันหรือเงื่อนไขไม่ว่าจะประเภทใดก็ตาม ไม่ว่าจะโดยชัดแจ้งหรือโดยนัย ในขอบเขตสูงสุดเท่าที่กฎหมายอนุญาต เราและผู้ให้บริการคอนเทนต์ของเรา (และบริษัทในเครือของผู้ให้บริการคอนเทนต์ดังกล่าว) ขอปฏิเสธการให้การรับรองหรือการรับประกันใดๆ ไม่ว่าจะโดยชัดแจ้งหรือโดยนัย
<br></br>(i) เกี่ยวกับกรรมสิทธิ์ คุณภาพ ความเหมาะสมสำหรับวัตถุประสงค์เฉพาะ การมีคุณภาพในระดับที่เป็นที่ยอมรับในทางการค้า ความถูกต้อง ความครบถ้วน หรือมาตรฐานของคุณภาพ และความคมชัด ความต่อเนื่องในการส่งผ่าน และการทำงานตามเวลาที่กำหนดของเว็บไซต์ บริการของ MVHub Plus ซอฟต์แวร์ และ/หรือคอนเทนต์ใดๆ
<br></br>(ii) ว่าเว็บไซต์ บริการของ MVHub Plus ซอฟต์แวร์ และ/หรือคอนเทนต์จะตรงตามข้อกำหนดของท่าน
<br></br>(iii) ว่าการเข้าถึงและใช้บริการของ MVHub Plus เว็บไซต์ และ/หรือคอนเทนต์โดยท่านจะไม่ถูกรบกวน ไม่มีความล่าช้าหรือความผิดพลาด ไม่มีไวรัส โค้ดที่เป็นอันตราย หรือส่วนประกอบที่อันตรายอื่นๆ และจะเป็นการใช้และเข้าถึงที่ปลอดภัย
<br></br>(iv) เกี่ยวกับหน้าที่ในการรักษาข้อมูลไว้เป็นความลับ (แม้ว่าแนวปฏิบัติที่ใช้บังคับในปัจจุบันของเราจะกำหนดให้มีการรักษาข้อมูลที่เป็นความลับดังกล่าวก็ตาม)
<br></br>(v) เกี่ยวกับผลลัพธ์ที่จะได้จากการใช้ส่วนหนึ่งส่วนใดของเว็บไซต์ บริการของ MVHub Plus ซอฟต์แวร์ และ/หรือคอนเทนต์ใดๆ เว้นแต่เมื่อมีการระบุเป็นอย่างอื่นโดยเฉพาะเจาะจงในข้อกำหนดการใช้งานฉบับนี้ และ
<br></br>(vi) ว่าการติดตั้งหรือการแสดงผลเว็บไซต์หรือซอฟต์แวร์ในอุปกรณ์ของท่าน หรือบริการของ MVHub Plus และ/หรือคอนเทนต์ จะไม่กระทบต่อความพร้อมใช้งานหรือความสามารถในการใช้งานของซอฟต์แวร์ แอปพลิเคชัน คอนเทนต์ และ/หรือบริการอื่นๆ
<br></br>(5) หากประเทศที่เกี่ยวข้องในตารางบริษัทในเครือไม่อนุญาตให้มีการปฏิเสธการรับประกันโดยนัยตามข้อ (ง) แต่อนุญาตให้มีการจำกัดการรับประกันในระดับสูงสุดได้ เราจะจำกัดการรับประกันของเราให้อยู่ในระดับดังกล่าว
<br></br>(6) เราไม่มีอำนาจควบคุมด้านการเรียบเรียงแก้ไขคอนเทนต์ใดโดยเฉพาะ และจะไม่กล่าวอ้างถึงความจริงแท้ของคอนเทนต์ดังกล่าว
<br></br>(7) ท่านยอมรับว่ารายการบางรายการและคอนเทนต์อื่นๆ บนเว็บไซต์และบริการของ MVHub Plus จะจัดหาให้โดยผู้ให้บริการที่เป็นบุคคลภายนอก โดยที่เราจะไม่ได้เป็นผู้จัดหารายการหรือคอนเทนต์ดังกล่าว และดังนั้น ความพร้อมในการให้บริการคอนเทนต์และรายการต่างๆ จะอยู่นอกเหนือความควบคุมของเรา
<br></br>(8) เราจะไม่ให้การรับรอง คำมั่น การรับประกัน คำสัญญา หรือการประกันต่อคุณภาพ ความคมชัด ความครบถ้วน ความถูกต้อง ความมีประโยชน์ ความต่อเนื่อง ความลื่นไหล หรือความตรงต่อเวลาของคอนเทนต์ใดๆ โดยเฉพาะ
<br></br>(9) ท่านยอมรับว่าคอนเทนต์จะเป็นการแสดงออกถึงมุมมองของผู้พูดของคอนเทนต์ดังกล่าวเท่านั้น และจะไม่ถือเป็นมุมมองของเราหรือหน่วยงานใดๆ ที่มีความเกี่ยวข้องกับเรา
<br></br>(10) เราขอปฏิเสธความรับผิดสำหรับคอนเทนต์ทั้งปวงที่มีอยู่บนเว็บไซต์และบริการของ MVHub Plus
<br></br>(11) อาจจะมีคอนเทนต์บางส่วนที่ไม่เหมาะสมที่จะให้บุคคลที่ยังไม่บรรลุนิติภาวะรับชม ท่านมีหน้าที่รับผิดชอบต้องตรวจสอบข้อมูลระบุตัวตนและ/หรืออายุของบุคคลใดๆ ที่ประสงค์หรือมีเจตนาที่จะรับชมคอนเทนต์ใดๆ ดังกล่าวก่อนที่ท่านจะอนุญาตให้บุคคลดังกล่าวรับชมคอนเทนต์นั้นๆ และโดยทั่วไปท่านจะต้องดูแลให้แน่ใจว่าบุคคลที่ยังไม่บรรลุนิติภาวะจะไม่เข้าถึงคอนเทนต์ดังกล่าวโดยใช้ข้อมูลการเข้าระบบและรหัสผ่านของท่าน
<br></br>(12) ในขอบเขตสูงสุดที่กฎหมายอนุญาต เราขอปฏิเสธความรับผิดโดยชัดแจ้งสำหรับสิ่งต่างๆ ต่อไปนี้
<br></br>(i) ความเสียหายที่เกิดขึ้นต่อข้อมูลหรือการสูญเสียข้อมูลที่เกิดขึ้นต่อท่าน ซึ่งเกิดจากการที่ท่านใช้เว็บไซต์ บริการของ MVHub Plus ซอฟต์แวร์ และ/หรือคอนเทนต์
<br></br>(ii) การใช้สิทธิเรียกร้องที่อ้างอิงมาจากสัญญา การละเมิด หรือสิ่งอื่นๆ หรือการสูญเสียรายได้ (ไม่ว่าจะโดยตรงหรือโดยอ้อม) การสูญเสียผลกำไร หรือการสูญเสียที่เป็นผลสืบเนื่องใดๆ ไม่ว่าจะมีลักษณะในทางธุรกิจหรือไม่ก็ตาม
<br></br>(iii) การใช้สิทธิเรียกร้องที่เกี่ยวข้องกับส่วนหนึ่งส่วนใดของเว็บไซต์ บริการของ MVHub Plus และ/หรือคอนเทนต์ ซึ่งจัดหา จัดให้ ขาย หรือเปิดให้เข้าถึงได้โดยหรือผ่านทางเว็บไซต์และ/หรือบริการของ MVHub Plus (หรือการไม่สามารถหรือความล่าช้าในการจัดหา จัดให้ ขาย หรือเปิดให้เข้าถึงสิ่งดังกล่าว)
<br></br>(iv) การแทรกแซง การระงับ ความล่าช้า หรือความล้มเหลวของเว็บไซต์และ/หรือบริการของ MVHub Plus หรือส่วนหนึ่งส่วนใดของสิ่งดังกล่าว ซึ่งเป็นผลมาจากเหตุการณ์หรือพฤติการณ์ที่อยู่นอกเหนือความควบคุมตามสมควรของเรา และ
<br></br>(v) การละเมิดสิทธิในทรัพย์สินทางปัญญาซึ่งเกิดจากการที่ท่านใช้เว็บไซต์ บริการของ MVHub Plus ซอฟต์แวร์ และ/หรือคอนเทนต์
<br></br><br></br>10.การจำกัดความรับผิดและการชดใช้
<br></br>(1) ในขอบเขตสูงสุดที่กฎหมายที่ใช้บังคับอนุญาต ไม่ว่าจะในพฤติการณ์ใด เราจะ
<br></br>(i) ไม่ยอมรับความรับผิดใดๆ ก็ตามสำหรับค่าเสียหายทางตรง ค่าเสียหายอันเนื่องมาจากการผิดสัญญา ค่าเสียหายที่เป็นผลสืบเนื่อง หรือค่าเสียหายทางอ้อม การสูญเสียหรือความเสียหายของข้อมูล การสูญเสียผลกำไร ค่าความนิยม ส่วนลด หรือโอกาส หรือการสูญเสียเงินออมที่คาดการณ์ไว้ หรือการสูญเสียอื่นใดที่เป็นผลมาจากการที่ท่านเข้าถึง อาศัยอ้างอิง หรือใช้ หรือการไม่สามารถใช้เว็บไซต์ บริการของ MVHub Plus และคอนเทนต์ ไม่ว่าจะอ้างอิงมาจากการรับประกัน สัญญา การละเมิด ความประมาทเลินเล่อ หรือทฤษฎีทางกฎหมายอื่นใด และไม่ว่าเราจะรู้ถึงความเป็นไปได้ที่จะเกิดความเสียหายดังกล่าวหรือไม่ก็ตาม และ
<br></br>(2) ท่านตกลงว่าจะชดใช้ให้แก่เรา บริษัทในเครือ ตัวแทน กรรมการ เจ้าหน้าที่ ลูกจ้าง ผู้ให้บริการคอนเทนต์ ผู้รับจ้างช่วง ผู้อนุญาตให้ใช้สิทธิ และ/หรือตัวแทนของเรา สำหรับการฟ้องร้อง ความรับผิด ต้นทุน การใช้สิทธิเรียกร้อง ความเสียหาย ค่าเสียหาย การดำเนินคดี และ/หรือค่าใช้จ่ายซึ่งเกิดขึ้นจาก เกี่ยวข้องกับ หรือซึ่งมีความเกี่ยวข้องกับ
<br></br>(i) การที่ท่านหรือบุคคลอื่นใช้เว็บไซต์ ซอฟต์แวร์ บริการของ MVHub Plus และ/หรือคอนเทนต์ผ่านทางอุปกรณ์และ/หรือบัญชีที่มีการลงทะเบียนไว้ของท่าน และ
<br></br>(ii) การที่ท่านฝ่าฝืนหรือไม่ปฏิบัติตามข้อกำหนดในการใช้งานฉบับนี้
<br></br><br></br>11.การมีผลบังคับใช้ต่อไปหลังสิ้นสุดสัญญา
<br></br>ข้อกำหนดในการใช้งานฉบับนี้ในหัวข้อ “การใช้เว็บไซต์โดยที่ท่านเป็นผู้รับความเสี่ยงเอง” “การจำกัดความรับผิดและการชดใช้ค่าเสียหาย” และ “ข้อกำหนดทั่วไป” จะมีผลบังคับใช้ต่อไปแม้หลังจากที่มีการยกเลิกหรือสิ้นสุดบัญชีผู้ใช้ MVHub Plus ของท่าน หรือการสิ้นสุดการเป็นสมาชิกของท่านสำหรับบริการของ MVHub Plus แบบคิดค่าบริการ
<br></br><br></br>12.การบอกกล่าว
<br></br>(1) โดยอยู่ภายใต้บังคับของกฎหมายที่ใช้บังคับ เราจะมีการบอกกล่าวเรื่องที่จำเป็นใดๆ ให้ท่านทราบโดยการโพสต์คำบอกกล่าวดังกล่าวบนเว็บไซต์ ท่านตกลงว่า
<br></br>(i) จะตรวจสอบเว็บไซต์เพื่อดูคำบอกกล่าว และ
<br></br>(ii) จะถือว่าท่านได้รับคำบอกกล่าวเรียบร้อยแล้วเมื่อมีการโพสต์คำบอกกล่าวดังกล่าวไว้บนเว็บไซต์
<br></br><br></br>13.ข้อกำหนดทั่วไป
<br></br>(1) หากข้อกำหนดใดๆ ของข้อกำหนดในการใช้งานฉบับนี้จะถูกตีความว่าไม่ชอบด้วยกฎหมาย มีผลไม่สมบูรณ์ หรือไม่สามารถบังคับใช้ได้ไม่ว่าจะด้วยเหตุใดก็ตาม ข้อกำหนดดังกล่าวจะไม่กระทบต่อความชอบด้วยกฎหมาย ความมีผลสมบูรณ์ หรือความสามารถในการบังคับใช้ของข้อกำหนดอื่นๆ ของข้อกำหนดในการใช้งานฉบับนี้ ข้อกำหนดที่ไม่ชอบด้วยกฎหมาย มีผลไม่สมบูรณ์ หรือไม่สามารถบังคับใช้ได้ดังกล่าวจะมีการแก้ไขเพิ่มเติมในขอบเขตเท่าที่จำเป็นเพื่อให้ข้อกำหนดดังกล่าวชอบด้วยกฎหมาย มีผลสมบูรณ์ หรือสามารถบังคับใช้ได้ หรือหากไม่สามารถทำเช่นนั้นได้ ให้ลบข้อกำหนดดังกล่าวออกจากข้อกำหนดในการใช้งานฉบับนี้ แต่ข้อกำหนดอื่นๆ ทั้งปวงของข้อกำหนดในการใช้งานฉบับนี้จะยังคงมีผลบังคับใช้โดยสมบูรณ์ต่อไป
<br></br>(2) กฎหมายที่ใช้บังคับและหน่วยงานยุติธรรมสำหรับการระงับข้อพิพาทที่เกี่ยวข้องกับข้อกำหนดในการใช้งานฉบับนี้จะระบุไว้ในตารางบริษัทในเครือ ข้อพิพาททั้งปวงที่เกิดขึ้นซึ่งเกี่ยวข้องกับข้อกำหนดในการใช้งานฉบับนี้จะต้องนำไปยื่นต่อเขตอำนาจของศาลที่ระบุอยู่ในตารางบริษัทในเครือ โดยขึ้นอยู่กับสถานที่ที่ท่านใช้และ/หรือเข้าถึงเว็บไซต์และ/หรือบริการของ MVHub Plus
<br></br>(3) การที่เราไม่บังคับตามสิทธิหรือข้อกำหนดใดๆ ตามข้อกำหนดในการใช้ฉบับนี้ จะไม่ถือว่าเป็นการสละสิทธิในการบังคับตามสิทธิหรือข้อกำหนดดังกล่าวหรือสิทธิหรือข้อกำหนดอื่นใด
<br></br>(4) เราจะไม่รับผิดหรือรับผิดชอบต่อการไม่สามารถปฏิบัติหน้าที่ใดๆ ของเราอันเนื่องมาจากเหตุที่อยู่นอกเหนือความควบคุมตามสมควรของเรา
<br></br>(5) หากมีความไม่สอดคล้องใดๆ ระหว่างข้อกำหนดในการใช้งานฉบับนี้ในฉบับภาษาอังกฤษและในฉบับภาษาอื่นใด (ถ้ามี) ฉบับภาษาอังกฤษจะมีผลบังคับใช้
<br></br>(6) โดยอยู่ภายใต้บังคับของกฎหมายที่ใช้บังคับ เราจะสามารถโอนสิทธิและหน้าที่ของเราตามข้อกำหนดในการใช้งานฉบับนี้ให้แก่บุคคลหรือหน่วยงานใดๆ โดยไม่ต้องได้รับความยินยอมจากท่าน นอกจากนี้ เรายังอาจใช้ตัวแทนหรือผู้รับจ้างช่วงในการปฏิบัติหน้าที่ความรับผิดชอบของเรา
<br></br>(7) ในขอบเขตสูงสุดที่กฎหมายที่ใช้บังคับอนุญาต บุคคลหรือหน่วยงานซึ่งไม่ได้เป็นคู่สัญญาตามข้อกำหนดในการใช้งานฉบับนี้จะไม่มีสิทธิตามมาตรา 374 แห่งประมวลกฎหมายแพ่งและพาณิชย์หรือกฎหมายใดที่คล้ายคลึงกันในประเทศใดๆ ในการที่จะบังคับตามส่วนหนึ่งส่วนใดของข้อกำหนดในการใช้งานฉบับนี้
<br></br>(8) กฎหมายที่ใช้บังคับ ข้อกำหนดการใช้งานนี้อยู่ภายใต้บังคับและตีความตามกฎหมายของประเทศไทย
<br></br>(9) สื่อวัสดุไม่พึงประสงค์  MVhub ไม่ยอมรับสื่อวัสดุหรือความคิดที่ไม่ได้ร้องขอสำหรับเนื้อหาของ MVhub และจะไม่รับผิดชอบต่อความคล้ายคลึงกันของเนื้อหาหรือการจัดทำโปรแกรมในสื่อใดก็ตามกับสื่อวัสดุหรือแนวความคิดที่ส่งไปยัง MVhub
<br></br>(10) ฝ่ายสนับสนุนลูกค้า หากต้องการข้อมูลเพิ่มเติมเกี่ยวกับบริการของ MVhub และคุณสมบัติต่างๆ หรือหากต้องการความช่วยเหลือทางด้านบัญชี โปรดไปที่ศูนย์ช่วยเหลือของ MVhub ในเว็บไซต์ของ MVhub ในบางกรณี ฝ่ายบริการลูกค้าอาจให้ความช่วยเหลือกับคุณได้ดีที่สุดโดยใช้เครื่องมือสนับสนุนการเข้าถึงจากระยะไกลซึ่ง MVhub จะสามารถเข้าถึงคอมพิวเตอร์ของคุณได้อย่างเต็มที่ หากคุณไม่ต้องการให้ MVhub เข้าถึงในลักษณะดังกล่าว คุณไม่ควรอนุญาตให้มีการสนับสนุนผ่านเครื่องมือการเข้าถึงจากระยะไกล และ MVhub จะให้ความช่วยเหลือกับคุณด้วยวิธีการอื่นแทน ในกรณีที่มีการขัดกันระหว่างข้อกำหนดการใช้งานและข้อมูลที่ฝ่ายสนับสนุนลูกค้าหรือส่วนอื่นๆ ของเว็บไซต์ของ MVhub นำเสนอใป ให้ข้อกำหนดการใช้งานฉบับนี้มีผลบังคับใช้แทน
<br></br>(11) การคงอยู่ หากข้อกำหนดใดในข้อกำหนดการใช้งานฉบับนี้ได้รับการพิจารณาว่าไม่สมบูรณ์ ไม่ชอบด้วยกฎหมาย หรือไม่สามารถบังคับใช้ได้ ให้ข้อกำหนดอื่นที่สมบูรณ์ ชอบด้วยกฎหมาย และสามารถบังคับได้จะยังคงมีผลและบังคับใช้ได้อย่างสมบูรณ์
<br></br>(12) การเปลี่ยนแปลงข้อกำหนดการใช้งานและการโอนสิทธิ์ MVhub อาจเปลี่ยนแปลงข้อกำหนดการใช้งานนี้เป็นครั้งคราว MVhub จะแจ้งให้คุณทราบล่วงหน้าอย่างน้อย 30 วันก่อนการเปลี่ยนแปลงดังกล่าวจะมีผลใช้บังคับกับคุณ MVhub อาจโอนสิทธิ์หรือโอนข้อตกลงของ MVhub ที่มีกับคุณรวมถึงสิทธิ์ที่เกี่ยวข้องและหน้าที่ของ MVhub ไม่ว่าเวลาใดก็ตาม และคุณตกลงที่จะให้ความร่วมมือกับ MVhub ในส่วนที่เกี่ยวข้องกับการโอนสิทธิ์หรือการโอนดังกล่าว
<br></br>(13) การสื่อสารผ่านระบบอิเล็กทรอนิกส์ MVhub จะส่งข้อมูลที่เกี่ยวข้องกับบัญชีของคุณ (เช่น การอนุมัติการชำระเงิน ใบแจ้งหนี้ การเปลี่ยนแปลงรหัสผ่านหรือวิธีการชำระเงิน ข้อความยืนยัน หนังสือแจ้ง) ในรูปแบบอิเล็กทรอนิกส์เท่านั้น ตัวอย่างเช่น ผ่านทางอีเมลไปยังที่อยู่อีเมลที่คุณให้ไว้ระหว่างการสมัครลงทะเบียน
<br></br><br></br>14.คำจำกัดความ
<br></br>“ตารางบริษัทในเครือ” หมายถึง ตารางบริษัทในเครือที่อยู่ท้ายข้อกำหนดในการใช้งานฉบับนี้
<br></br>“กฎหมายที่ใช้บังคับ” ซึ่งเกี่ยวข้องกับบุคคล หน่วยงาน การกระทำ หรือสิ่งใด หมายถึงสิ่งต่างๆ ต่อไปนี้ ซึ่งเกี่ยวข้องกับบุคคล การกระทำ หรือสิ่งดังกล่าว
<br></br>(1) กฎหมาย กฎ หรือข้อบังคับใดๆ ของประเทศใดๆ (หรือเขตการปกครองของรัฐของประเทศหนึ่ง)
<br></br>(2) หน้าที่ตามสัญญาการอนุญาตให้ใช้สิทธิในประเทศใดๆ (หรือเขตการปกครองของรัฐของประเทศหนึ่ง) และ
<br></br>(3) การกำหนด การตัดสินชี้ขาด หรือคำสั่งของผู้บังคับกฎหมายในประเทศใดๆ (หรือเขตการปกครองของรัฐของประเทศหนึ่ง) ซึ่งชอบด้วยกฎหมายและมีผลผูกพัน
<br></br>“คอนเทนต์” รวมถึงสิ่งต่างๆ ที่ท่านอาจมองเห็น ได้อ่าน ได้ฟัง ดาวน์โหลด หรือเข้าถึงในหรือผ่านทางเว็บไซต์ (รวมถึงแต่ไม่จำกัดเพียงรายการ ละคร ภาพยนตร์ ซีรีส์ โชว์ ส่วนต่อประสานกับผู้ใช้ ฟีเจอร์ การทำงาน การวางเลย์เอาต์ ข้อความ ไฟล์ ข้อมูล รหัสจุดหมาย ซอฟต์แวร์ รูปภาพ ภาพถ่าย ภาพวาด ตัวอักษร และสิ่งอื่นๆ)
<br></br>“ซอฟต์แวร์” หมายถึง ซอฟต์แวร์ แอปพลิเคชัน และ/หรือโปรแกรมที่มาจากบริการของ MVHub Plus หรือที่ใช้ในการเข้าถึงบริการของ MVHub Plus
<br></br>“ไทย” หมายถึง ราชอาณาจักรไทย
<br></br>“บริการของ MVHub Plus แบบไม่คิดค่าบริการ” มีความหมายตามที่ให้คำจำกัดความไว้ตามข้อ 2(ก)(ii)
<br></br>“บริการของ MVHub Plus แบบคิดค่าบริการ” มีความหมายตามที่ให้คำจำกัดความไว้ตามข้อ 2(ก)(ii)
<br></br>“บริการของ MVHub Plus” หมายถึง บริการวิดีโอออนไลน์ที่ใช้ชื่อในปัจจุบันว่า “MVHub Plus” ซึ่งจัดให้แก่ท่านผ่านทางเว็บไซต์ และรวมถึงบริการของ MVHub Plus แบบไม่คิดค่าบริการ บริการของ MVHub Plus แบบคิดค่าบริการ และการเข้าถึงรายการ ละคร ภาพยนตร์ ซีรีส์ โชว์ เนื้อหา ฟีเจอร์ และการทำงานทั้งปวงที่จัดให้เข้าถึงบนเว็บไซต์
<br></br>“เรา” หรือ “ของเรา” หมายถึง หมายถึงบริษัทในเครือของ MVHub Plus International Limited ซึ่งดำเนินการเว็บไซต์ในภูมิภาคของท่านตามที่ระบุไว้ในตารางบริษัทในเครือ
<br></br><br></br>อัพเดตเมื่อ [วันที่ 01 ธันวาคม 2566]


        </Card>
      </Container>
    </>
  )
}
