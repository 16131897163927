/*eslint-disable*/
import React from "react";
import '../../assets/css/style.css'
import img1 from '../../assets/img/body/Andriod.png'
import img2 from '../../assets/img/body/apple.png'
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';

function FooterBlack() {
  return (
    <>
      <footer className="footer-black bg-dark sticky-bottom">
        <Container>
          <Row className='pb-1 align-items-center'>
            <Col xs='4'>
            <div className='text-footer'>©2023 MvHub+. All Rights Reserved.<br/>
                <Link to={'/policy/term-of-use'} className='footer_btn not-A'>ข้อตกลงการใช้งาน </Link> <Link to={'/policy/term-and-conditions'} className='footer_btn not-A'>ข้อกำหนดและเงื่อนไข</Link> <Link to={'/policy/cancel-subscription'} className='footer_btn not-A'>การยกเลิกแผนการเป็นสมาชิกของท่าน</Link>
                <br/>
                <Link to={'/policy/term-of-use-eng'} className='footer_btn not-A'>Terms of Use</Link> <Link to={'/policy/term-and-conditions-eng'} className='footer_btn not-A'>Term And Condition</Link> <Link to={'/policy/cancel-subscription-eng'} className='footer_btn not-A'>Canceling your membership plan</Link>
            </div>
            </Col>
            <Col xs='4'>
              <div>
                ติดต่อเรา
              </div>
              <i className="fa fa-facebook m-2 not-A footer_btn" onClick={() => window.open('https://www.facebook.com/MVHub/')} />
              <i className="fa fa-instagram m-2 not-A footer_btn" onClick={() => window.open('https://www.instagram.com/mvhub_official/')} />
              <i className="fa fa-youtube m-2 not-A footer_btn" onClick={() => window.open('https://www.youtube.com/channel/UC4ie08e0v_KSpd7S1sKAmmw')} />
            </Col>
            <Col xs='4'>
              <div>
                {/* Download Mvhub App */}
              </div>
              <Row>
                <Col>
                  <img src={img1} class="m-1 img-footer footer_btn"
                    onClick={() => window.open('https://play.google.com/store/apps/details?id=com.mvhub.mvhubplus&hl=th&gl=US')}
                  />
                  <img src={img2} class="m-1 img-footer footer_btn"
                    onClick={() => window.open('https://apps.apple.com/th/app/mvhub-plus/id1530585776')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
