import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Container, Row } from 'reactstrap'

export default function History() {
    const [datas, setDatas] = useState('')
    const [isLoading, setIsLoading] = useState(null)

    const loadDatas = () => {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/series`, 'data', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(result => {
            setDatas(result.data.res_data.vod.filter(data => data.playlist_type == 'continue'))
        }).finally(() => setIsLoading(false))
    }

    const deleteHistory = (contentId) => {
        axios.put(`${process.env.REACT_APP_API_CONTENT2}/delete/continuewatching`, {
            ep_id: contentId
        }, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(result => {
        }).finally(() => {
            loadDatas()
        })
    }
    useEffect(() => {
        setIsLoading(true)
        loadDatas()
    }, [])
    if (isLoading === false && datas[0]) {
        const renderCard = datas[0].item.map(data => {
            return (
                <Card style={{ 'background-color': 'rgba(14,14,14,0.8)' }}>
                    <div className='gird-col text-center'>
                        <button className='close-btn' onClick={() => deleteHistory(data.ep_id)}>ลบ</button>
                        <Link to={`/vdo/${data.vod_id}/ep/EP.${data.ep_num}?play=${data.current}`}>
                            <img src={data.imgY1} alt='' />
                            <div>{data.ch_name}</div>
                        </Link>
                    </div>
                </Card>
            )
        })
        return (
            <div className='bg-img'>
                <br />
                <Container className='pb-5'>
                    <Row className='justify-content-center'>
                        <Card className='p-3 m-4' style={{ backgroundColor: 'rgb(14,14,14)' }}>
                            <Col>
                                <Row className='text-center'>
                                    <Col>
                                        <Link className='not-A' to='/login/account'>
                                            <i class="fa fa-address-card fa-2x"></i>
                                            <div>
                                                บัญชี
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link className='not-A' to='/login/fav'>
                                            <i class="fa fa-align-justify fa-2x"></i>
                                            <div>
                                                รายการโปรด
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link className='not-A' to='/login/history'>
                                            <i class="fa fa-history fa-2x"
                                                style={{ color: '#fff200' }}
                                            ></i>
                                            <div>
                                                ประวัติการรับชม
                                            </div>
                                        </Link>
                                    </Col>
                                    {/* <Col>
                                        <Link className='not-A' to='/login/package'>
                                            <i class="fas fa-box-open fa-2x"
                                            ></i>
                                            <div>
                                                แพ็คเกจ
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link className='not-A' to='/login/coupon'>
                                            <i class="fas fa-ticket-alt fa-2x"
                                            ></i>
                                            <div>
                                                แลกคูปอง
                                            </div>
                                        </Link>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Card>
                        <Container>
                            <Row>
                                <Col>
                                    <div className='grid-col'>
                                        {renderCard}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Row >
                </Container>
            </div>
        )
    } else {
        return <div className='bg-img'>
            <br />
            <Container className='pb-5'>
                <Row className='justify-content-center'>
                    <Card className='p-3 m-4' style={{ backgroundColor: 'rgb(14,14,14)' }}>
                        <Col>
                            <Row className='text-center'>
                                <Col>
                                    <Link className='not-A' to='/login/account'>
                                        <i class="fa fa-address-card fa-2x"></i>
                                        <div>
                                            บัญชี
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/fav'>
                                        <i class="fa fa-align-justify fa-2x"></i>
                                        <div>
                                            รายการโปรด
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/history'>
                                        <i class="fa fa-history fa-2x"
                                            style={{ color: '#fff200' }}
                                        ></i>
                                        <div>
                                            ประวัติการรับชม
                                        </div>
                                    </Link>
                                </Col>
                                {/* <Col>
                                    <Link className='not-A' to='/login/package'>
                                        <i class="fas fa-box-open fa-2x"
                                        ></i>
                                        <div>
                                            แพ็คเกจ
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/coupon'>
                                        <i class="fas fa-ticket-alt fa-2x"
                                        ></i>
                                        <div>
                                            แลกคูปอง
                                        </div>
                                    </Link>
                                </Col> */}
                            </Row>
                        </Col>
                    </Card>
                </Row >
            </Container>
            {isLoading === true &&
                <div className='text-center'>loading...</div>
            }
        </div>
    }
}
