/*eslint-disable*/
import React from "react";
import '../../assets/css/style.css'
import img1 from '../../assets/img/body/Andriod.png'
import img2 from '../../assets/img/body/apple.png'
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';

function FooterFixed() {
  return (
    <>
      <Container className="footerFixed">
        <Row className='pb-1 align-items-center'>
          <Col xs='4'>
            <div className='text-footer'>©2023 MvHub+. All Rights Reserved.<br/>
                <Link to={'/policy/term-of-use'} className='footer_btn not-A'>ข้อตกลงการใช้งาน </Link> <Link to={'/policy/term-and-conditions'} className='footer_btn not-A'>ข้อกำหนดและเงื่อนไข</Link> <Link to={'/policy/cancel-subscription'} className='footer_btn not-A'>การยกเลิกแผนการเป็นสมาชิกของท่าน</Link>
                <br/>
                <Link to={'/policy/term-of-use-eng'} className='footer_btn not-A'>Terms of Use</Link> <Link to={'/policy/term-and-conditions-eng'} className='footer_btn not-A'>Term And Condition</Link> <Link to={'/policy/cancel-subscription-eng'} className='footer_btn not-A'>Canceling your membership plan</Link>
            </div>
          </Col>
          <Col xs='4'>
            <div>
              ติดต่อเรา
            </div>
            <i className="fa fa-facebook m-2 not-A footer_btn" onClick={() => window.open('https://www.facebook.com/MVHub/')} />
            <i className="fa fa-instagram m-2 not-A footer_btn" onClick={() => window.open('https://www.instagram.com/mvhub_official/')} />
            <i className="fa fa-youtube m-2 not-A footer_btn" onClick={() => window.open('https://www.youtube.com/channel/UC4ie08e0v_KSpd7S1sKAmmw')} />
          </Col>
          <Col xs='4'>
            <div>
            </div>
            <Row>
              <Col>
                <img src={img1} class="m-1 img-footer footer_btn"
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=com.mvhub.mvhubplus&hl=th&gl=US')}
                />
                <img src={img2} class="m-1 img-footer footer_btn"
                  onClick={() => window.open('https://apps.apple.com/th/app/mvhub-plus/id1530585776')}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <footer className="footer opa100 footer-black footer-white bg-dark fixed-bottom">
          <Container>
            <Row className='pb-1 align-items-center'>
              <Col sm='4'>
                <div className=''>
                  ข้อกำหนดการใช้งาน นโยบายความเป็นส่วนตัว
                  ©2021 MvHub+. All Rights Reserved.
                </div>
              </Col>
              <Col sm='4'>
                <div>
                  ติดต่อเรา
                </div>
                <Link to='https://www.facebook.com/MVHub/' target='_blank' className='not-A footer_btn'>
                  <i className="fa fa-facebook m-2" />
                </Link>
                <Link to='https://www.instagram.com/mvhub_official/' target='_blank' className='not-A footer_btn'>
                  <i className="fa fa-instagram m-2" />
                </Link>
                <Link to='https://www.youtube.com/channel/UC4ie08e0v_KSpd7S1sKAmmw' target='_blank' className='not-A footer_btn'>
                  <i className="fa fa-youtube m-2" />
                </Link>
              </Col>
              <Col sm='4'>
                <div>
                  Download Mvhub App
                </div>
                <Row>
                  <Col>
                    <i class="fab fa-google-play m-2"
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.mvhub.mvhubplus&hl=th&gl=US')}
                    />
                    <i class="fab fa-app-store-ios m-2"
                      onClick={() => window.open('https://apps.apple.com/th/app/mvhub-plus/id1530585776')}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </footer> */}
    </>
  );
}

export default FooterFixed;
