import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {
    Container,
    Row,
    Col,
    CustomInput,
} from "reactstrap";

// core components
import { Route, Redirect, Switch, Link, useParams } from "react-router-dom";

function Search(props) {
    let search = useParams()
    const renderMenu = props.datas.res_data.map(result => {
        return (
            <Link to={`/vdo/${result.vod_id}`}>
                    <img className='subbanner-img pt-1 pb-1' src={result.imgY1} />
            </Link>
        )
    })

    return (
        <>
            <div className='bg-blank'>
                <Container>
                    <h2 className='pt-5 ml-2'>{search.search}</h2>
                    <div className='grid-col pt-5 pb-5 ml-5 mr-5'>
                        {renderMenu}
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Search;
