import React, { useState, useEffect } from 'react'
import '../../assets/css/login.css'
import {
    Container,
    Col,
    Row
} from "reactstrap";
import 'reactjs-line-login/dist/index.css';
import axios from 'axios';

export default function Register() {
    const [input, setInput] = useState({})
    const [msg, setMsg] = useState()
    const [statusCode, setStatusCode] = useState('0000')
    function inputChange(event) {
        const { name, value } = event.target;
        setInput((prevInput) => {
            return {
                ...prevInput,
                [name]: value
            };
        });
    }

    function register(event) {
        event.preventDefault()
        axios.put(`${process.env.REACT_APP_API_CONTENT2}/register/summit`, {
            username: input.username,
            password: input.password,
            user_phone: input.phone,
            email: input.email,
            name: input.fname,
            gender: input.gener,
            birthday: input.bday
        }).then(result => {
            if (result.data.res_code == '0000') {
                alert('การสมัครสำเร็จ กรุณาตรวจสอบ Email')
                window.location.href = '/login'
            }
        })
    }
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/register`, {
            "username": input.username,
            "password": input.password,
            "user_phone": input.phone,
            "email": input.email,
            "name": input.fname,
            "gender": input.gener,
            "birthday": input.bday
        }).then(result => {
            setMsg(result.data.res_msg)
            setStatusCode(result.data.res_code)
        })
    }, [input])
    return (
        <div className='bg-img'>
            <br />
            <Container className='pb-5'>
                <Row>
                    <Col className='mt-5 mb-5'>
                        <form onSubmit={register}>
                            <div id="loginform">
                                <h2 id="headerTitle" style={{ color: 'white' }}>สมัครสมาชิก</h2>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>บัญชีผู้ใช้</label>
                                    <input id='username' name='username' style={{ color: 'black' }} type='text' placeholder='username' onChange={(event) => inputChange(event)} />
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>รหัสผ่าน</label>
                                    <input id='password' name='password' style={{ color: 'black' }} type='password' placeholder='password' onChange={(event) => inputChange(event)} />
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>ยืนยันรหัสผ่าน</label>
                                    <input id='confirm_password' name='confirm_password' style={{ color: 'black' }} type='password' placeholder='password' onChange={(event) => inputChange(event)} />
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>โทรศัพท์</label>
                                    <input id='phone' name='phone' style={{ color: 'black' }} type='tel' placeholder='Tel' onChange={(event) => inputChange(event)} />
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>อีเมล</label>
                                    <input id='email' name='email' style={{ color: 'black' }} type='email' placeholder='e-mail' onChange={(event) => inputChange(event)} />
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>ชื่อ นามสุกล</label>
                                    <input id='fname' name='fname' style={{ color: 'black' }} type='text' placeholder='John Dose' onChange={(event) => inputChange(event)} />
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>เพศ</label>
                                    <select id="gender" name="gender" style={{ color: 'black' }}>
                                        <option value="">กรุณาเลือก</option>
                                        <option value="male">ชาย</option>
                                        <option value="female">หญิง</option>
                                    </select>
                                </div>
                                <div class="login-row">
                                    <label style={{ color: 'white' }}>วันเดือนปีเกิด (dd/mm/yyyy)</label>
                                    <input id='bday' name='bday' style={{ color: 'black' }} type='text' placeholder='01/01/2024' onChange={(event) => inputChange(event)} />
                                </div>
                                {statusCode != '0000' &&
                                    <div class="login-row" style={{ color: 'red' }}>
                                        {msg.replace('duplicate', 'ข้อมูลมีการใช้แล้ว')}
                                    </div>
                                }
                                {input.password != input.confirm_password &&
                                    <div class="login-row" style={{ color: 'red' }}>
                                        รหัสผ่านไม่ตรงกัน
                                    </div>
                                }
                                {statusCode == '0000' && input.password == input.confirm_password &&
                                    < div id="button" className="login-row">
                                        <button type='submit' className='login-btn' >ยืนยัน</button>
                                    </div>
                                }
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}
