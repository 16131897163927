import React from 'react'
import {
  Container,
  Card
} from "reactstrap";

export default function TermTH() {
  return (
    <>
      <Container className='pt-5'>
        <Card className='warning bg-img'>
        <h1>Terms of Use</h1>
        <br></br><br></br>
<br></br>1. Important announcement
<br></br>(1) Please read these Terms of Use. (“Terms of Use”) in detail, as these Terms of Use govern your use of the MVHub Plus services, which can be accessed through the MVHub Plus website at www.mvhubplus.com. and the MVHub Plus application (collectively the “Website”), your use and/or access to the Website You agree to be bound by these Terms of Use. (as may be updated from time to time)
<br></br>(2) We operate the website. However, we have no control over the editing of some of the content on the website and, in some cases, other third parties. is the provider of the content displayed on the website or is the operator of parts of the website or other Internet websites that you may visit or access through the website (“Third Party Service Providers”)
<br></br>(3) We will update these Terms of Use from time to time. It is your responsibility to check for updates to these Terms of Use when they become effective after they are posted on the Website. Your continued use of the Website after that there are changes or amendments to the said terms and conditions Regardless of whether you have reviewed the said changes or amendments or not. It will be considered that you agree to be bound by such changes or amendments to the terms and conditions. Except when applicable law stipulates otherwise.
<br></br><br></br>2. Registration and free trial of the service
<br></br>(1) MVHub Plus services
<br></br>(i) Among other things, your ability to access the MVHub Plus Services through various devices; This will depend on the operating system and/or browser installed on your device.
<br></br>(ii) we provide certain MVHub Plus Services to users free of charge (“Free MVHub Plus Services”) and provide other content, features, and/or services; Some of these services are available to users who subscribe to one of our paid services, MVHub Plus Premium, after payment of the applicable subscription fee. MVHub Plus Premium is collectively referred to as the “MVHub Plus Paid Services. Services” and will be referred to separately as “MVHub Plus Paid Services”. For more details about the free MVHub Plus Services and the MVHub Plus Paid Services, please visit https:// www.mvhubplus.com
<br></br>(iii) The free MVHub Plus Services will be accessible in any jurisdiction in which we offer such free services. Each paid MVHub Plus Service is subject to limited use and functionality. Access only in the territory in which you have subscribed to the MVHub Plus paid service.
<br></br>(2) Use of MVHub Plus services via mobile device networks may incur mobile data usage charges. Please contact the data provider on your mobile device for more information.
<br></br>(3) You may be required to register with us in order to access certain parts of the website. (“Features accessible after registration”) in the event that registration is required for you.
<br></br>(i) You must provide accurate, complete, and updated registration information.
<br></br>(ii) you must maintain any username and password; that we provide to you safely and
<br></br>(iii) you authorize us to assume that any person using the website using your username and password; It will be you or the person you authorize to use the said username and password to access the website.
<br></br>(4) You agree that your use of certain features accessible after registration may be subject to additional terms and conditions. In such case You will be informed. and such additional terms and conditions
<br></br>(i) shall apply to your use of features accessible after such registration; in addition to these Terms of Use; and
<br></br>(ii) will supersede these Terms of Use; To the extent that inconsistencies occur.
<br></br><br></br>3. Service
<br></br>(1) You accept that at any time We may, in our sole discretion, take the following actions: Regardless of whether there is notice or not.
<br></br>(i) turn off and/or suspend the MVHub Plus service at any time; To maintain, upgrade, test and/or repair the system.
<br></br>(ii) change the website; (or any part of the website) and
<br></br>(iii) stop providing the website; Stop providing the MVHub Plus Service (or any part of the MVHub Plus Service) and/or any Content to you.
<br></br><br></br>4. Service fees
<br></br>(1) To the extent permitted by applicable law. We reserve the right to take the following actions at any time.
<br></br>(i) charge a service fee for your use of the free MVHub Plus service; and
<br></br>(ii) change or amend the terms and conditions covering such service fees; For accessing or using the website in whole or in part.
<br></br><br></br>5. Content
<br></br>(1) You must be 18 years of age or older to access or use MVHub Plus services and/or content.
<br></br>(2) You are permitted to use MVHub Plus's services and view content for your own personal, non-commercial use only. It must comply with these Terms of Use.
<br></br>(3) You agree that the MVHub Plus service and content are for your personal viewing and access only. and you must not display, play, use, access, or view the MVHub Plus Services and/or Content. or allow any other person to display, play, use, access, or view MVHub Plus services and/or content for public or business purposes. except with our prior written consent.
<br></br>(4) In your use and/or access to MVHub Plus services, you must comply with all applicable laws. including any rules of conduct and/or use, as may be amended from time to time.
<br></br>(5) Without limiting the scope of Sections 5(2) and 5(3) above, you shall not charge any fees for use of or access to the MVHub Plus Services and/or any Content or for Access to any location where MVHub Plus Services and/or Content are viewed.
<br></br>(6) You agree that the content on the website will be protected by copyright. and it is possible to have other intellectual property rights except as permitted by law or with our express permission. You must not act. or allow any other person to do the following actions:
<br></br>(i) sell, reproduce, distribute, modify, display, distribute, publicly perform; Create derivative works based on, republish, or use any Content in any manner for any public or business purpose without our prior written consent.
<br></br>(ii) use any Content on any other website or computer network for any purpose;
<br></br>(iii) reverse engineer any Content which incorporates downloadable software; and/or
<br></br>(iv) otherwise infringe the intellectual property rights of any person or entity in your use of and/or access to the Website or any Content;
<br></br>(7) Anything you do on or in connection with the website. This will not be considered a transfer of intellectual property rights to you. or permit you to use any intellectual property rights, except where expressly stated.
<br></br>(8) You accept that
<br></br>(i) We may change, substitute or remove any content, channels and programs (whether or not advertised) from the MVHub Plus Service, including changing or reducing the number of broadcast hours of any channel or program. any items on the MVHub Plus service and
<br></br>(ii) expand, reduce, remove and/or modify any portion of the MVHub Plus Service and/or any Content; remove, disable and/or suspend transmission or streaming of the MVHub Plus Service and/or any Content; nt which we consider in our sole opinion to be in violation of applicable law or otherwise unacceptable.
<br></br><br></br>6. Use by you
<br></br>(1) You must not
<br></br>(i) interfere with or disrupt the Website, MVHub Plus Services, servers, networks, software, hardware or equipment connected to or through the Website and/or MVHub Plus Services;
<br></br>(ii) violate any applicable law in your use of the MVHub Plus Website and/or Services;
<br></br>(iii) collect or store personal information about other users of the MVHub Plus Website and/or Services;
<br></br>(iv) inhibit or inhibit any other person from using the MVHub Plus Website and/or Services, including without limitation, by “hacking” or otherwise altering any portion of the MVHub Plus Website and/or Services;
<br></br>(v) access or use the Website, MVHub Plus Services, Content, or Software for any unlawful purpose or any purpose not expressly permitted by these Terms of Use;
<br></br>(vi) modify, modify, sublicense, reproduce, distribute, copy, exploit, translate, sell, reverse engineer, decompile or disassemble any portion of the Website, MVHub Plus Services, Software and/or Content;
<br></br>(vii) remove copyright notices; trademark or other ownership rights contained in the Website, MVHub Plus Services and/or Content.
<br></br>(viii) copy and/or reproduce any portion of the Website, MVHub Plus Services and/or the Content without our prior written permission;
<br></br>(ix) use robots, spiders, applications for searching or gathering information from the website; or any manual or other automated device or process that collects, indexes, “data mines” or otherwise reproduces, disrupts, or circumvents. to the navigational structure or display of the Website, MVHub Plus Services and/or Content;
<br></br>(x) hack, break in, or attempt to hack or break in; in any manner to the website, MVHub Plus services, software and/or any storage on our or any third party servers;
<br></br>(xi) download, copy, store, cut, distribute, share, or redirect any content from the Website and/or MVHub Plus Services in any manner or through any media;
(<br></br>xii) compile software or other materials that contain viruses, malicious code, worms, time bombs, Trojan horses.
<br></br><br></br>7. Links and Advertising
<br></br>(1) We have no control over any third party websites which are connected to the MVHub Plus website and/or services and are not responsible for the content or accuracy of any off-site web pages or other third party websites. Any connection to the MVHub Plus website and/or services (including but not limited to websites linked through advertisements)
<br></br>(2) Some links appearing on the MVHub Plus website and/or services are generated automatically. and may be offensive or inappropriate for some people. Inclusion of any links This does not indicate that we support such linked websites. and you must use such links at your own risk.
<br></br>(3) Your contact or agreement or participation in promotions of advertisers on the Website and/or MVHub Plus Services will be deemed to be between you and such advertisers only. We shall not be liable or responsible for any loss or damage of any kind incurred as a result of any such dealings. or as a result of displaying links and advertisements on the MVHub Plus Website and/or Services.
<br></br>(4) We may use third-party advertising companies. including but not limited to Google Ad, Oracle Data Cloud, Integral Ad Science, Lotame and Nielsen, to track and/or measure advertising effectiveness and/or enhance our users' experience on our websites, MVHub Plus services and Other Websites These companies may use certain information (such as a non-personally identifiable identifier, IP address, but not your name, address, email address or telephone number) about your visit. View the website, MVHub Plus services, and other websites to measure their advertising effectiveness and display advertisements about goods and services of interest to you.
<br></br>(5) We collect and use the content and public information you provide to other websites, including but not limited to your public profiles on your social media accounts (such as your Facebook account, if you access the website and/or MVHub Plus services through logging into your Facebook account) in order to provide MVHub Plus services and display advertisements about products and services of interest to you.
<br></br><br></br>8. Device IDs and other information about website visits
<br></br>(1) During your use and/or access to the website We collect information about the activity of the devices used to visit the website. Including device identifiers, “cookies” and website viewing behavior Your use of the website By using this website, you agree to our use of device identifiers and/or cookies in accordance with our privacy notice. Privacy Notice and our Cookie Notice
<br></br>(2) We use third-party advertising companies to display advertisements on our website. These companies may use information about your device's activity (not including your name, address, email address, or telephone number) (including device identifiers and/or cookies) on this and other websites. To measure advertising effectiveness and display advertisements that may be of interest to you
<br></br>(3) We and/or our data processors may transfer device identifiers and/or cookies to third party service providers hired by us located outside Thailand. You agree to the transfer of your device identifiers and/or cookies to the third party service provider. We will ensure that such third party service providers enforce data protection measures and policies that are no less stringent than our own.
<br></br><br></br>9.Using the MVHub Plus website and services is at your own risk.
<br></br>(1) You use the MVHub Plus website and services at your own risk. You must evaluate and assume all risks associated with the use of any Content. Including reliance on the accuracy, completeness, or usefulness of any content. All information displayed on or through the MVHub Plus Website and Services is for informational purposes only. You should obtain your own independent advice regarding any Content.
<br></br>(2) We make every effort to provide a convenient and functional website. However, we do not guarantee that the content will be error-free. or that the website or servers used to operate the website will be free of viruses or other harmful components.
<br></br>(3) If your use of the MVHub Plus website and/or services results in the need for service or replacement of property, materials, equipment, or data, we will not be responsible for such costs.
<br></br>(4) Without limiting the scope of the above provisions. You agree that the MVHub Plus website and services are provided “as is” and without warranties or conditions of any kind. whether expressly or impliedly To the fullest extent permitted by law. We and our content providers (and its affiliates) expressly disclaim any representations or warranties. whether expressly or impliedly
<br></br>(i) regarding ownership, quality, fitness for a particular purpose; Having quality at a level that is acceptable in trade, accuracy, completeness, or standards of quality and clarity and continuity in transmission. and the scheduled operation of the website, MVHub Plus services, software and/or any content.
<br></br>(ii) that the MVHub Plus Website, Services, Software and/or Content will meet your requirements;
<br></br>(iii) that your access to and use of the MVHub Plus Services, Website and/or Content will not be interrupted; No lags or glitches, no viruses, malicious code. or other dangerous components and will be safe to use and access
<br></br>(iv) regarding the duty to maintain confidentiality of information; (Although our current applicable practices require such confidentiality.)
<br></br>(v) regarding the results to be obtained from the use of any portion of the Website, MVHub Plus Services, Software and/or any Content; unless otherwise specifically stated in these Terms of Use; and
<br></br>(vi) that the installation or display of the Website or Software on your device; or MVHub Plus services and/or content This will not affect the availability or usability of any other software, applications, content and/or services.
<br></br>(5) If the applicable country in the Affiliate Schedule does not allow the disclaimer of implied warranties in clause (d), but allows the maximum limitations of warranties, We will limit our warranty to that level.
<br></br>(6) We have no control over the editing of any specific content. and will not claim the authenticity of such content.
<br></br>(7) You accept that certain programs and other content MVHub Plus' website and services are provided by third party service providers. We are not the provider of such programs or content and therefore the availability of such content and programs will be beyond our control.
<br></br>(8) We make no representations, promises, warranties, promises, or guarantees of quality, clarity, completeness, accuracy, or usefulness. the continuity, fluidity or timeliness of any particular content;
<br></br>(9) You agree that the content expresses the views of the speaker of said content only. and will not be considered the view of us or any agency. that are related to us
<br></br>(10) We disclaim all liability for all content contained on the MVHub Plus website and services.
<br></br>(11) There may be some content that is inappropriate for underage people to view. You are responsible for verifying the identity and/or age of any person. that wishes or intends to view any content as mentioned before you allow such person to view that content. In general, you must ensure that underage persons do not access such content using your login and password.
<br></br>(12) To the fullest extent permitted by law. We expressly disclaim liability for the following:
<br></br>(i) damage to data or loss of data caused to you; which arises from your use of the website, MVHub Plus services, software and/or content
<br></br>(ii) exercising claims based in contract, tort or otherwise; or loss of income (whether direct or indirect) loss of profits or any consequential loss. Regardless of whether it is of a business nature or not.
<br></br>(iii) claims in connection with any portion of the Website, MVHub Plus Services and/or Content provided, provided, sold or otherwise made available by or through the MVHub Plus Website and/or Services; (or the inability or delay in procuring, providing, selling or making available any such thing)
<br></br>(iv) any interference, suspension, delay, or failure of the MVHub Plus Website and/or Services or any portion thereof; which results from events or circumstances beyond our reasonable control; and
<br></br>(v) infringement of intellectual property rights arising from your use of the Website, MVHub Plus services, software and/or content.
<br></br><br></br>10. Limitation of Liability and Indemnification
<br></br>(1) To the fullest extent permitted by applicable law. Under no circumstances will we
<br></br>(i) does not accept any liability; for direct damages Damages resulting from breach of contract Consequential damages or indirect damages Loss or corruption of data loss of profits, goodwill, discounts or opportunities or loss of anticipated savings; or any other loss resulting from your access, reliance on, or use of, or inability to use, the Website, MVHub Plus Services, and Content, whether based on warranty, contract, tort, negligence, or or any other legal theory and whether we know of the possibility of such damage or not; and
<br></br>(2) You agree to compensate us. Affiliated companies, agents, directors, officers, employees, content providers subcontractor Licensor and/or our representatives For lawsuits, liabilities, costs, claims, damages, damages, proceedings and/or expenses arising from related to or which is related to
<br></br>(i) your or anyone else's use of the Website, Software, MVHub Plus Services and/or Content through your device and/or registered account; and
<br></br>(ii) your violation or failure to comply with these Terms of Use.
<br></br><br></br>11. Continued effectiveness after the contract ends.
<br></br>This Terms of Use section The “Use of the Site at Your Own Risk,” “Limitation of Liability and Indemnification,” and “General Terms” shall survive any cancellation or termination of your MVHub Plus account, or Termination of your membership for MVHub Plus paid services.
<br></br><br></br>12.Notice
<br></br>(1) Subject to applicable law. We will provide any necessary notice. to you by posting such notice on the Website, you agree that
<br></br>(i) will check the website for notices; and
<br></br>(ii) You will be deemed to have received notice when such notice is posted on the Website.
<br></br><br></br>13. General requirements
<br></br>(1) If any requirements of these Terms of Use shall be construed as unlawful. has incomplete results or cannot be enforced for any reason. Such provisions shall not affect the legality of the matter. validity or the enforceability of other provisions. of these Terms of Use Unlawful terms has incomplete results Such unenforceable or unenforceable provision will be amended to the extent necessary to render such provision lawful, valid or enforceable. or if unable to do so Such provisions shall be deleted from these Terms of Use. But other requirements All provisions of these Terms of Use will continue in full force and effect.
<br></br>(2) The applicable law and judicial authority for resolving disputes related to these Terms of Use are specified in the Affiliates table. All disputes arising in connection with these Terms of Use shall be submitted to the jurisdiction of the courts listed in the Affiliates table. This depends on where you use and/or access the MVHub Plus website and/or services.
<br></br>(3) Our failure to enforce any rights or requirements. According to this Terms of Use It shall not be considered a waiver of the right to enforce such right or provision or any other right or provision.
<br></br>(4) We will not be liable or responsible for your failure to perform any duties. of us due to causes beyond our reasonable control.
<br></br>(5) If there are any inconsistencies Between the English version of these Terms of Use and any other language versions (if any), the English language version will govern.
<br></br>(6) Subject to applicable law. We may transfer our rights and obligations under these Terms of Use to any person or entity. without your consent. We may also use agents or subcontractors to perform our responsibilities.
<br></br>(7) To the fullest extent permitted by applicable law. A person or entity which is not a party to these Terms of Use shall have no rights under Section 374 of the Civil and Commercial Code or any similar law in any country. In order to enforce any part of these Terms of Use,
<br></br>(8) Governing law These Terms of Use are governed by and construed in accordance with the laws of Thailand.
<br></br>(9) Unsolicited Materials MVhub does not accept unsolicited materials or ideas for MVhub Content and is not responsible for the similarity of content or programming in any medium to such materials or ideas. sent to MVhub
<br></br>(10) Customer Support For more information about MVhub's services and features, Or if you need help with accounting Please visit the MVhub Help Center on MVhub's website. In some cases, Customer Support may be best able to assist you using remote access support tools where MVhub will have full access to your computer. If you do not want MVhub to have such access, You should not allow support through remote access tools, and MVhub will provide you with assistance through other means. In the event of a conflict between these Terms of Use and information provided by Customer Support or otherwise, of MVhub's website, these Terms of Use shall apply instead. 
<br></br>(11) Survival. If any provision of these Terms of Use is determined to be invalid, unlawful or cannot be enforced Provide complete other requirements Legal and can be enforced will remain fully effective and enforceable.
<br></br>(12) Changes to Terms of Use and Assignment MVhub may change these Terms of Use from time to time. MVhub will notify you at least 30 days prior to such change becoming effective for you. MVhub may transfer Rights or transfer MVhub's agreement with you and MVhub's related rights and obligations at any time. and you agree to cooperate with MVhub in connection with any such assignment or transfer.
<br></br>(13) Electronic Communications MVhub will send you information related to your account (such as payment authorizations, invoices, password or payment method changes). Confirmation messages, notices) in electronic form only, for example via email to the email address you provided during registration.
<br></br><br></br>14. Definition
<br></br>“Affiliate Table” means the Affiliate Table at the end of these Terms of Use.
<br></br>“Applicable law” with respect to a person, entity, act or thing means any of the following: with respect to such person, entity, act or thing.
<br></br>(1) any law, rule or regulation of any country (or subdivision of a country)
<br></br>(2) Obligations under licensing agreements in any country. (or administrative division of a country's state) and
<br></br>(3) determination of final decision or orders of law enforcers in any country (or subdivision of a country's state) which is legal and binding
<br></br>“Content” includes various things. that you may see, read, listen to, download, or access on or through the website (including but not limited to programs, dramas, movies, series, shows, user interfaces, features, functionality, layouts, text, files, data, destination code, software, images, photographs, drawings, characters, and other things)
<br></br>“Software” means the software, applications, and/or programs provided by the MVHub Plus Services or used to access the MVHub Plus Services.
<br></br>“Thailand” means the Kingdom of Thailand.
<br></br>“Free MVHub Plus Services” has the meaning as defined in Section 2(a)(ii).
<br></br>“MVHub Plus Paid Services” has the meaning as defined in Section 2(a)(ii).
<br></br>“MVHub Plus Service” means the online video service currently known as “MVHub Plus” made available to you through the Website. and includes MVHub Plus services that are free of charge, MVHub Plus services that are for a fee and access to all programmes, dramas, movies, series, shows, content, features and functionality made available on the Website.
<br></br>“We” or “our” means the MVHub Plus International Limited affiliate which operates the website in your region as specified in the affiliate table.
<br></br><br></br>Updated on [01 December 2023]


        </Card>
      </Container>
    </>
  )
}
