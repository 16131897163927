import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
    Button,
    Input,
    InputGroupAddon,
    InputGroup,
    Container,
    Row,
    Col,
    Card
} from "reactstrap";

export default function Coupon() {
    const [code, setCode] = useState()
    const redeem = (event) => {
        event.preventDefault()
        if (localStorage.getItem('auth')) {
            axios.post(`${process.env.REACT_APP_API_CONTENT2}/package/redeem`, {
                'redeem': code
            }, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('auth')}`
                }
            }).then(result => alert(result.data.res_msg))
        } else {
            alert('กรุณาเข้าสู่ระบบก่อน')
        }
    }
    return (
        <div className='bg-img'>
            <br />
            <Container className='pb-5'>
                <Row className='justify-content-center'>
                    <Card className='p-3 m-4' style={{ backgroundColor: 'rgb(14,14,14)' }}>
                        <Col>
                            <Row className='text-center'>
                                <Col>
                                    <Link className='not-A' to='/login/account'>
                                        <i class="fa fa-address-card fa-2x"></i>
                                        <div>
                                            บัญชี
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/fav'>
                                        <i class="fa fa-align-justify fa-2x"></i>
                                        <div>
                                            รายการโปรด
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/history'>
                                        <i class="fa fa-history fa-2x"></i>
                                        <div>
                                            ประวัติการรับชม
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/package'>
                                        <i class="fas fa-box-open fa-2x"
                                        ></i>
                                        <div>
                                            แพ็คเกจ
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className='not-A' to='/login/coupon'>
                                        <i class="fas fa-ticket-alt fa-2x"
                                            style={{ color: '#fff200' }}
                                        ></i>
                                        <div>
                                            แลกคูปอง
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Row>
                            <Col>
                                <form onSubmit={redeem}>
                                    <InputGroup size='lg'>
                                        <InputGroupAddon addonType="prepend">
                                            <Button color='success' className='pb-1 package-btn' type='submit'>ใส่รหัสโปรโมชั่น
                                            </Button>
                                        </InputGroupAddon>
                                        <Input onChange={(event) => setCode(event.target.value)} />
                                    </InputGroup>
                                </form>
                            </Col>
                        </Row>
                    </Card>
                </Row >
            </Container>
        </div>
    )
}
