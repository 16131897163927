import React, { useRef, useEffect } from 'react';
import shaka from 'shaka-player';
import '../../assets/css/controls.css'

const ShakaPlayerWithDRMAndAds = ({ url, license, linkads }) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  var manifestUri = url;
  var drmConfig = license;
  var adTagUrl = "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpremidpostpod&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&cmsid=496&vid=short_onecue&correlator="; //linkads.current;
  console.log("=======ADS========");
  //console.log(manifestUri);
  //console.log(drmConfig);
  //console.log(adTagUrl);

  useEffect(() => {
    if (!videoRef.current || !containerRef.current) return;

    const video = videoRef.current;
    const container = containerRef.current;
    const player = new shaka.Player(video);
    //const ui = new shaka.ui.Overlay(player, container, video);
    //const controls = ui.getControls();

    const licenseServer = `https://wv.miratech.io/wvproxy/clicense?contentid=${license}`;

    const drmConfig = {
      servers: {
        'com.widevine.alpha': licenseServer,
        'com.microsoft.playready':'https://mspr.miratech.io/playready/rightsmanager.asmx'
      },
      advanced: {
        'com.widevine.alpha': {
          persistentStateRequired: true,
          videoRobustness: 'SW_SECURE_CRYPTO', // Adjust as needed
          audioRobustness: 'SW_SECURE_CRYPTO', // Adjust as needed
        },
      },
    };

    // Install built-in polyfills to patch browser incompatibilities.
    shaka.polyfill.installAll();

    // Check to see if the browser supports the basic APIs Shaka needs.
    if (shaka.Player.isBrowserSupported()) {
      initPlayer();
    } else {
      console.error('Browser not supported!');
    }

    async function initPlayer() {
      try {
        // Configure DRM if provided
        if (drmConfig) {
          player.getNetworkingEngine().registerRequestFilter(function (type, request) {
            if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
              request.headers["Content-Type"] = "application/json";
            }
          });

          player.configure({
            drm: drmConfig,
          });
        }

        player.configure({
          streaming: {
            bufferingGoal: 100,
            bufferBehind: 100,
            rebufferingGoal: 60
          }
        })

        const uiConfig = {
          seekBarColors: {
            base: 'rgba(255, 255, 255, 0.3)',
            buffered: 'rgba(255, 255, 255, 0.9)',
            played: 'rgb(255, 242, 0)',
            adBreaks: 'rgb(255, 242, 0)',
          },
          volumeBarColors: {
            base: 'rgba(255, 255, 255, 0.3)',
            level: 'rgb(255, 242, 0)'
          },
          addBigPlayButton: true
        };;

        // Load the manifest.
        await player.load(manifestUri);

        // Configure ads if provided
        if (adTagUrl) {
          const adsConfiguration = {
            ima: {
              serverUrl: adTagUrl,
            },
          };
          //player.configure('manifest.dash.ignoreMinBufferTime', true);
          // player.configure('manifest.hls.ignoreMinBufferTime', true);
          // player.configure('manifest.ignoreTextStreamFailures', true);
          // player.configure('manifest.ignoreImageStreamFailures', true);
          // player.configure('manifest.ignoreVideoStreamFailures', true);
          // player.configure('manifest.ignoreAudioStreamFailures', true);
          // player.configure('manifest.ignoreManifestProgramDateTime', true);

          player.configure(adsConfiguration);
        }

        // This runs if the asynchronous load is successful.
        console.log('The manifest has been loaded!');
      } catch (e) {
        // onError is executed if the asynchronous load fails.
        console.error('Error loading manifest:', e);
      }
    }

    // Listen for errors from the player.
    player.addEventListener('error', onErrorEvent);

    function onErrorEvent(event) {
      onError(event.detail);
    }

    function onError(error) {
      console.error('Shaka Player Error', error);
    }

    return () => {
      player.destroy();
    };
  }, [manifestUri, drmConfig, adTagUrl]);

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      <video
        ref={videoRef}
        style={{ width: '100%', height: 'auto' }}
        controls
        autoPlay
        crossOrigin="anonymous"
      />
    </div>
  );
};

export default ShakaPlayerWithDRMAndAds;