import React, { useState, useEffect } from "react";
import FooterBlack from "../../components/Footers/FooterBlack";
import SectionNavigation from "../index-sections/SectionNavigation.js";
import Body from "./Body";
import { Route, Switch, Redirect } from 'react-router-dom';
import Ep from './Ep';

export default function Vdo() {

    return (
        <>
            <Switch>
                <Route path='/vdo/:id/ep/:ep' exact component={() => <Ep />} />
                <Route path="/vdo/:id" exact render={() => <>
                    <div className="bg-blank">
                        <SectionNavigation />
                        {/* <Header img={datas} /> */}
                        <Body />
                        <FooterBlack />
                    </div>
                </>
                }
                />
                <Redirect to="/" />
            </Switch>
        </>
    )
}
