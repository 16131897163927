import React, { useState, useEffect } from "react";
import '../../assets/css/style.css'
import FooterBlack from "components/Footers/FooterBlack.js";
import SectionNavigation from "../index-sections/SectionNavigation.js";

import loadingImg from '../../assets/img/body/loading.gif'
import axios from "axios";
import Sub from "./Sub";
import { getBrowser } from "getBrowser";

function SubPages() {
    const [datas, setDatas] = useState()
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        if (localStorage.getItem('auth')) {
            axios.post(`${process.env.REACT_APP_API_CONTENT2}/series`, { device: getBrowser() }, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('auth')}`
                },
            })
                .then(result => {
                    setDatas(result)
                    setIsLoading(false)
                })
        } else {
            axios.post(`${process.env.REACT_APP_API_CONTENT2}/guest-series`, { device: getBrowser() })
                .then(result => {
                    setDatas(result)
                    setIsLoading(false)
                })
        }
    }, [])

    return (
        <>
            {isLoading === true &&
                <div className='bg-loading'>
                    <img src={loadingImg} className='loading-img' alt='loading' />
                </div>
            }
            {isLoading === false &&
                <div className='bg-blank'>
                    <SectionNavigation />
                    {/* <Header img={datas} /> */}
                    <Sub datas={datas} />
                    <FooterBlack />
                </div>
            }
        </>
    );
}

export default SubPages;
